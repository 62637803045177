import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import BgBottom from "../../assets/images/testimonials-bg-bottom.svg";
import BgTop from "../../assets/images/testimonials-bg-top.svg";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import SlickNext from '../../assets/images/slick-next.png'
import SlickPrev from '../../assets/images/slick-prev.png'
import { createClient } from "contentful";
import config from "../../config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const Testimonial = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Grid
      item
      xs={12}
      md={6}
     
      width="100%"
      pr={isMobile ? "20px" :"52px" }
      pb={isMobile ? "64px" : "40px"}
    >
      <Stack width="100%">
        <Typography
          variant="proxima700"
          color="darkRed.main"
          fontSize="19px"
          mb="20px"
        >
          {props.text}
        </Typography>
        <Typography variant="proxima700" color="darkRed.main" fontSize="14px">
          {props.name}
        </Typography>
        <Typography variant="proxima700" color="darkRed.main" fontSize="14px">
          {props.company}
        </Typography>
      </Stack>
    </Grid>
  );
};

const Testimonials = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const settings = {
    arrows: true,
    dots: true,
 
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: isMobile ? 1 : 2,
    speed: 300,
    nextArrow: <img src={SlickNext} />,
    prevArrow: <img src={SlickPrev} />,
    cssEase: "linear",
  };

  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "testimonial",
      });
      console.log(resp.items);
      setTestimonials(resp.items);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        backgroundColor="white"
        px={isMobile ? "24px" : "80px"}
        pt={isMobile ? "64px" : "84px"}
        sx={{
          backgroundImage: isMobile ? `url(${BgBottom})` : `url(${BgBottom}), url(${BgTop})`,
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPositionY: "bottom, top",
          backgroundPositionX: "0%, right",
          backgroundSize: "835px 170px , 835px 170px",
        }}
      >
        <Typography variant="proxima600" color="darkRed.main" fontSize="18px" >
          Testimonials
        </Typography>

        <Box width="100%" mt="20px">
          <Slider {...settings}>
            {testimonials.map((testimonial) => {
              return (
                <div>
                  <Testimonial
                    text={testimonial.fields.testimonialText}
                    name={testimonial.fields.name}
                    company={testimonial.fields.company}
                  />
                </div>
              );
            })}
          </Slider>
        </Box>
      </Box>
      <Box px={isMobile ? "24px" : "80px"}>
        <Grid container spacing={1} width="100%">
          <Grid pt={isMobile ? "60px" : "40px"} item xs={12}>
            <Typography
              variant="arizona"
              color="darkRed.main"
              fontSize="44px"
              lineHeight="50px"
            >
              Ecliptic does good work with good people.
            </Typography>
          </Grid>
          <Grid item xs={12} pt="18px">
            <Typography
              variant="proxima400"
              color="darkRed.main"
              fontSize={isMobile ? "24px" : "22px"}
            >
              Our process is nontraditional but so are our Innovators.
            </Typography>
          </Grid>
        </Grid>

        <Box mt="44px" display="flex">
          <Link to="/pitch-us">
            <Box
              height="39px"
              backgroundColor="brightOrange.main"
              width={props.small ? "100%" : "auto"}
              px="24px"
              borderRadius="4px"
              mb="60px"
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="proxima600"
                    fontSize="16px"
                    color="darkestBlue.main"
                    sx={{pt:"2px"}}
                  >
                    PITCH US
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Testimonials;
