import React from "react";
import Box from "@mui/material/Box";
import Background from "../../../assets/images/footer-accessory-background.png";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Stack from "@mui/material/Stack";
import UpRightArrow from "../../../assets/images/up-right-arrow-black.svg";
import { useMediaQuery } from "react-responsive";

const InvestorInnovatorFooter = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          pl: "56px",
          pt: "34px",
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "repeat",
          backgroundSize: "contain",
          
        }}
      >
        <Stack
          alignItems="center"
          spacing={isMobile ? 10 : 3}
          sx={{
            pr: "56px",
            borderRight: 1,
            borderColor: "#596379",
            py: "30px",
          }}
        >
          <Typography
            variant="arizona"
            fontWeight="300px"
            color="electricGreen.main"
            fontSize={isMobile ? "64px" : "30px"}
            lineHeight={isMobile ? "56px" : undefined}
            textAlign="center"

          >
            Are you an Investor?
          </Typography>
          <Typography
            textAlign="center"
            variant="proxima400"
            color="electricGreen.main"
            fontSize="22px"
            lineHeight="32px"
          >
            We know our investment partners appreciate a view behind the
            curtain. With Ecliptic, you’ll know our strategies and moves every
            step of the way. Curious about how we fund?
          </Typography>
          <Box item display="flex" justifyContent="center" alignItems="bottom">
            <Link to="/investors">
              <Box
                height="39px"
                backgroundColor="electricGreen.main"
                width={props.small ? "100%" : "auto"}
                px="44px"
                borderRadius="4px"
                mb="30px"
                
              >
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                 
                >
                  <Stack direction="row" spacing={2}>
                    <Typography
                      variant="proxima600"
                      fontSize="16px"
                      color="darkestBlue.main"
                      letterSpacing=".7px"
                      sx={{pt:"2px"}}
                    >
                      LEARN MORE
                    </Typography>
                    <img src={UpRightArrow} />
                  </Stack>
                </Box>
              </Box>
            </Link>
          </Box>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          px: "56px",
          pt: "64px",
          pb: "95px",
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "repeat",
        backgroundSize: "contain",
        }}
      >
        <Stack alignItems="center" spacing={isMobile ? 10 : 3}>
          <Typography
            variant="arizona"
            fontWeight="300px"
            color="brightOrange.main"
            fontSize={isMobile ? "64px" : "30px"}
            lineHeight={isMobile ? "56px" : undefined}
            textAlign="center"
          >
            Are you an Innovator?
          </Typography>
          <Typography
            textAlign="center"
            variant="proxima400"
            color="brightOrange.main"
            fontSize="22px"
            lineHeight="32px"
          >
            We know how hard entrepreneurs, startup founders, and small business
            owners work. That’s why we support our Innovators where they are.
            Curious about who we fund?
          </Typography>
          <Box item display="flex" justifyContent="center">
            <Link to="/innovators">
              <Box
                height="39px"
                backgroundColor="brightOrange.main"
                width={props.small ? "100%" : "auto"}
                px="44px"
                borderRadius="4px"
              >
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={2}>
                    <Typography
                      variant="proxima600"
                      fontSize="16px"
                      color="darkestBlue.main"
                      letterSpacing=".7px"
                      sx={{pt:"2px"}}
                    >
                      LEARN MORE
                    </Typography>
                    <img src={UpRightArrow} />
                  </Stack>
                </Box>
              </Box>
            </Link>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default InvestorInnovatorFooter;
