import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DivLogo from "../../assets/images/div-inc-logo.svg";
import FutureLogo from "../../assets/images/future-vc-logo.svg";
import GreentownLogo from "../../assets/images/greentown-labs-logo.svg";
import LaciLogo from "../../assets/images/laci-logo.svg";
import MoodyLogo from "../../assets/images/moody-logo.svg";
import ColumbiaLogo from "../../assets/images/columbia-logo.svg";
import RiceLogo from "../../assets/images/rice-logo.svg";
import UTLogo from "../../assets/images/ut-logo.svg"
import DellMedLogo from "../../assets/images/dell-med-logo.svg"
import DukeLogo from "../../assets/images/duke-logo.svg"
import TexasInnovationLogo from "../../assets/images/texas-innovation-logo.png"
import MassChallengeLogo from "../../assets/images/mass-challenge-logo.svg"
import { useMediaQuery } from "react-responsive";
const Partners = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Stack width="100%" pt="84px" justifyContent="center" alignItems="center">
      <Typography variant="arizona" fontSize="44px" px={isMobile ? "24px" : "56px"} color="navy.main">
        Ecliptic Partner Program
      </Typography>
      <Grid container justifyContent="center" spacing={8} py="64px" px="44px">
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
          <img src={DivLogo} style={{maxWidth:isMobile ? "90%" : "140px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center" >
          <img src={FutureLogo} style={{maxWidth:isMobile ? "90%" :"65px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
          <img src={UTLogo} style={{maxWidth:isMobile ? "90%" :"90px"}} />
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
          <img src={GreentownLogo} style={{maxWidth:isMobile ? "90%" :"280px"}} />
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center" >
          <img src={LaciLogo} style={{maxWidth:isMobile ? "90%" :"85px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center" >
          <img src={DellMedLogo} style={{maxWidth:isMobile ? "90%" :"85px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center" >
          <img src={DukeLogo} style={{maxWidth:isMobile ? "90%" :"85px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center" >
          <img src={ColumbiaLogo} style={{maxWidth:isMobile ? "90%" :"85px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center"> 
          <img src={TexasInnovationLogo}style={{objectFit:"contain",maxWidth:isMobile ? "90%" :"340px"}} />
        </Grid>
  
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
          <img src={RiceLogo} style={{maxWidth:isMobile ? "90%" :"200px"}}/>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
          <img src={MassChallengeLogo} style={{maxWidth:isMobile ? "90%" :"140px"}}/>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Partners;
