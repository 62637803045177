import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import LifeSciences from "../../assets/images/life-sciences-inv.svg";
import IdentitySecurity from "../../assets/images/identity-and-security-inv.svg";
import EnergyMaterials from "../../assets/images/energy-and-materials-inv.svg";
import PreSeed from "../../assets/images/pre-seed.svg";
import Seed from "../../assets/images/seed.svg";
import SeriesA from "../../assets/images/series-a.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const CaseStudies = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="darkRed.main"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      pb="100px"
    >
      <Grid container spacing={1} width="100%">
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="White"
            fontSize="44px"
            lineHeight="50px"
          >
            What are we looking for?
          </Typography>
        </Grid>
        <Grid item xs={12} pt="18px" >
          <Typography variant="proxima400" color="white" fontSize="22px">
            Ecliptic gives funds to Innovators with their hands in multiple
            markets simultaneously.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} py="56px">
        <Grid item xs={12} sm={12} md={4}>
          <Box
            height={isMobile ? undefined : "324px"}
            backgroundColor="red.main"
            px="34px"
            textAlign="center"
          >
            <Stack spacing={3} justifyContent="flex-start" pt="34px">
              <img style={{ height: "44px" }} src={EnergyMaterials}></img>
              <Typography fontSize="24px" color="white" variant="proxima700">
                Energy & Materials
              </Typography>
              <Typography fontSize="17px" variant="proxima400" color="white" pb={isMobile ? "18px" : undefined}>
                This is the place for scientific breakthroughs. Diverse tech
                with huge impacts: climate tech, energy breakthroughs, food
                security, nanoparticles, and more.
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height={isMobile ? undefined : "324px"}
            backgroundColor="red.main"
            alignItems="center"
            textAlign="center"
            px="34px"
          >
            <Stack spacing={3} justifyContent="flex-start" pt="34px">
              <img style={{ height: "44px" }} src={LifeSciences}></img>
              <Typography fontSize="24px" color="white" variant="proxima700">
                Life Sciences
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="white"
                whiteSpace="pre-line"
                pb={isMobile ? "18px" : undefined}
              >
                To save lives and impact our communities—while ensuring steady
                value—we look for scalable solutions with multiple adjacencies
                from healthcare to medtech.
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height={isMobile ? undefined : "324px"}
            backgroundColor="red.main"
            display="flex"
            justifyContent="center"
            textAlign="center"
            px="34px"
          >
            <Stack spacing={3} justifyContent="flex-start" pt="34px">
              <img style={{ height: "44px" }} src={IdentitySecurity}></img>
              <Typography fontSize="24px" color="white" variant="proxima700">
                Identity & Security
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="white"
                whiteSpace="pre-line"
                pb={isMobile ? "18px" : undefined}s
              >
                To guarantee safety and quality, we find innovators who protect
                our networks, technology, and personal data sovereignty for the long
                term.
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={3}
        width="100%"
        py={isMobile ? undefined : "30px"}
      >
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="White"
            fontSize={isMobile ? "48px" : "44px"}
            lineHeight={isMobile ? "56xp" : "50px"}
          >
            How and when we fund
          </Typography>
        </Grid>
        <Grid item xs={12} pt="18px" mb="100px">
          <Typography variant="proxima400" color="white" fontSize="22px">
            Whether early stage or later stage, our capital is applied to
            accelerate your innovation towards a scalable, sustainable
            enterprise with global potential.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box
            height="175px"
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Stack
              height="75%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <img src={PreSeed} width="128px"></img>
              <Typography
                variant="arizona"
                color="#FFD6A9"
                lineHeight="44px"
                fontSize="39px"
               
              >
                Pre-Seed
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height="175px"
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Stack
              height="75%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <img src={Seed} width="128px"></img>
              <Typography
                variant="arizona"
                color="#FFD6A9"
                lineHeight="44px"
                fontSize="39px"
               
              >
                Seed
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height={isMobile ? "175px" : "120px" }
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Stack
              height="75%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <img src={SeriesA} width="128px"></img>
              <Typography
                variant="arizona"
                color="#FFD6A9"
                lineHeight="44px"
                fontSize="39px"
               
              >
Series A              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudies;
