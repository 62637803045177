import Box from "@mui/material/Box";
import React from "react";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import Leaf from "../../assets/images/portfolio-leaf.jpg";
import { Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Commitment = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const background = {
    image: Leaf,
    speed: -20,
    opacity: [0.7, 0.7],
    expanded: true,
  };

  const headline = {
    
    translateY: [24, 25],

    shouldAlwaysCompleteAnimation: true,
    expanded: true,
    children: (
      <div className="absolute inset-0 flex items-center justify-center">
        <Typography
          mx="auto"
          variant="arizona"
          fontSize={isMobile ? "24px" : "44px"}
          textAlign={isMobile ? "center" : undefined}
          color="white"
          className="text-8xl text-white font-thin"
          display="inline"
          sx={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          We are committed. High engagement brings 21% higher earnings.
        </Typography>
        <Typography
          width="100%"
          variant="arizona"
          fontSize={isMobile ? "14px" : "24px"}
          textAlign={isMobile ? "center" : undefined}
          color="white"
          className="text-8xl text-white font-thin"
          display="inline"
          mr="44px"
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "right",
          }}
        >
          —Forbes 
        </Typography>
        
      </div>
    ),
  };
  return (
    <Box sx={{ overflow: "hidden", height: isMobile ? "170px" : "280px" }} backgroundColor="#5048DB">
      <ParallaxBanner
        style={{ aspectRatio: "2 / 1" }}
        layers={[background, headline]}
      ></ParallaxBanner>
    </Box>
  );
};

export default Commitment;
