import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import LifeSciences from "../../assets/images/life-sciences-inv.svg";
import IdentitySecurity from "../../assets/images/identity-and-security-inv.svg";
import EnergyMaterials from "../../assets/images/energy-and-materials-inv.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { createClient } from "contentful";
import config from "../../config";

const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const Focus = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [portfolioCompanies, setPortfolioCompanies] = useState([]);

  useEffect(() => {
    fetchData();
    // console.log(articles);
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "portfolioCompany",
      });
      const companies = [];
      console.log(resp.items);
      resp.items.forEach((item) => {
        if (item.fields.featured && item.fields.logoWhite) {
          companies.push(item);
        }
      });
      console.log(companies);
      setPortfolioCompanies(companies);

      console.log(resp.items);
    } catch (error) {
      console.log(error);
    }
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    useTransfor: false,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  return (
    <Box backgroundColor="#0B5364" pt={isMobile ? "64px" : "84px"}>
      <Grid container spacing={1} width="100%" px={isMobile ? "24px" : "80px"}>
        <Grid item xs={12}>
          <Typography variant="proxima600" color="white" fontSize="18px">
            How we do it
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="White"
            fontSize="44px"
            lineHeight="50px"
          >
            Our focus areas
          </Typography>
        </Grid>
        <Grid item xs={12} pt="18px">
          <Typography variant="proxima400" color="white" fontSize="22px">
            At Ecliptic, we have 100+ years of combined experience in operating startups, achieving successful exits, and investing and financing the right deals. We know how to build, scale, and
            exit. We also know when and where—exactly—to invest.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} py="56px" px={isMobile ? "24px" : "80px"}>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            height={isMobile ? undefined : "324px"}
            backgroundColor="darkGreen.main"
            px="34px"
            pb={isMobile ? "34px" : undefined}
            textAlign="center"
          >
            <Stack spacing={3} justifyContent="flex-start" pt="34px">
              <img style={{ height: "44px" }} src={EnergyMaterials}></img>
              <Typography fontSize="24px" color="white" variant="proxima700">
                Energy & Materials
              </Typography>
              <Typography fontSize="17px" variant="proxima400" color="white">
                This is the place for scientific breakthroughs. Diverse tech
                with huge impacts: climate tech, energy breakthroughs, food
                security, nanoparticles, and more.
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height={isMobile ? undefined : "324px"}
            backgroundColor="darkGreen.main"
            alignItems="center"
            textAlign="center"
            px="34px"
            pb={isMobile ? "34px" : undefined}
          >
            <Stack spacing={3} justifyContent="flex-start" pt="34px">
              <img style={{ height: "44px" }} src={LifeSciences}></img>
              <Typography fontSize="24px" color="white" variant="proxima700">
                Life Sciences
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="white"
                whiteSpace="pre-line"
              >
                To save lives and impact our communities—while ensuring steady
                value—we look for scalable solutions with multiple adjacencies
                from healthcare to medtech.
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height={isMobile ? undefined : "324px"}
            backgroundColor="darkGreen.main"
            display="flex"
            justifyContent="center"
            textAlign="center"
            px="34px"
            pb={isMobile ? "34px" : undefined}
          >
            <Stack spacing={3} justifyContent="flex-start" pt="34px">
              <img style={{ height: "44px" }} src={IdentitySecurity}></img>
              <Typography fontSize="24px" color="white" variant="proxima700">
                Identity & Security
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="white"
                whiteSpace="pre-line"
              >
                To guarantee safety and quality, we find innovators who protect
                our networks, technology, and personal data sovereignty for the long
                term.
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Box mt="40px" pb="40px" width="100%">
        <Slider {...settings}>
          {portfolioCompanies.map((company) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  height="154px"
                  width="323px"
                  src={"https:" + company.fields.logoWhite.fields.file.url}
                ></img>
              </div>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default Focus;
