import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import MikeErwin from "../../assets/images/mike-erwin.jpg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Twitter from "../../assets/images/twitter-logo-blue.svg";
import LinkedIn from "../../assets/images/linked-in-logo-blue.png";
import { useMediaQuery } from "react-responsive";

import { createClient } from "contentful";
import config from "../../config";

const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const PeopleCard = (props) => {
  console.log(props.headshot);
  return (
    <Box
      backgroundColor="white"
      borderRadius="12px"
      height="261px"
      width="100%"
      px="28px"
      py="28px"
    >
      <Stack
        display="flex"
        justifyContent="center"
        spacing={1}
        alignItems="center"
      >
        <img src={props.headshot} height="107px" />

        <Typography
          variant="proxima700"
          color="darkestBlue.main"
          fontSize="24px"
        >
          {props.name}
        </Typography>
        <Typography variant="proxima400" color="#596379" fontSize="14px">
          {props.jobTitle}
        </Typography>
        <Stack direction="row" spacing={3}>
          {props.twitter && (
            <a href={props.twitter}>
              <img src={Twitter} style={{ height: "26px" }} />
            </a>
          )}
          {props.linkedIn && (
            <a target="_blank" href={props.linkedIn}>
              <img src={LinkedIn} style={{ height: "26px" }} />
            </a>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const PeopleList = () => {
  const [people, setPeople] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "peopleProfile",
      });
      console.log(resp.items);
      setPeople(resp.items);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box backgroundColor="#E4E6F3">
      <Grid container spacing={2} px={isMobile ? "24px" : "56px"} py="80px">
        <Grid item xs={12} mb="24px">
          <Typography variant="arizona" color="navy.main" fontSize="44px">
            The people behind Ecliptic
          </Typography>
        </Grid>

        {people.map((person) => {
          return (
            <Grid item xs={12} md={6} lg={3}>
              <PeopleCard
                headshot={"https:" + person.fields.headshot.fields.file.url}
                name={person.fields.name}
                jobTitle={person.fields.title}
                twitter={person.fields.twitter}
                linkedIn={person.fields.linkedIn}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Box
            backgroundColor="#F6F7FC"
            id="test"
            sx={{ overflow: "hidden" }}
            borderRadius="12px"
          >
            <Grid container>
              <Grid item xs={12} md={6} lg={3}>
                <Stack
                  px="28px"
                  py="28px"
                  display="flex"
                  justifyContent="center"
                  spacing={1}
                  alignItems="center"
                  backgroundColor="white"
                  height="100%"
                >
                  <img src={MikeErwin} height="107px" />
                  <Typography
                    variant="proxima700"
                    color="darkestBlue.main"
                    fontSize="24px"
                  >
                    {"Mike Erwin"}
                  </Typography>
                  <Typography
                    variant="proxima400"
                    color="#596379"
                    fontSize="14px"
                  >
                    {"Founder"}
                  </Typography>
                  <Typography
                    variant="proxima400"
                    color="#596379"
                    fontSize="14px"
                  >
                    {"1969-2023"}
                  </Typography>
                  <Box></Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={9}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#F6F7FC"
                >
                  <Typography
                    variant="proxima400"
                    color="darkestBlue.main"
                    fontSize="19px"
                  
                    mx="44px"
                    my="44px"
                  >
                    {
                      "A visionary entrepreneur and the founder of Ecliptic Capital, Mike Erwin led with his keen sense of timing when it came to identifying the optimal moment in a startup’s lifecycle for investment. His grand vision—that he succeeded in doing time and time again—was to support and nurture innovators, while providing patient capital and guidance throughout their entrepreneurial journey. With his vision ingrained in our DNA, Ecliptic will forever carry Mike's legacy as its north star."
                    }
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PeopleList;
