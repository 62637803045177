import React from "react";
import Hero from "./Components/hero";
import Header from "../SharedComponents/header";
import { useMediaQuery } from "react-responsive";
import Transparency from "./Components/transparency";
import Promises from "./Components/promises";
import Strategy from "./Components/strategy";
import Responsibility from "./Components/responsibility";
import SilverLining from "./Components/silverLining";
import InvestorInnovatorFooter from "../SharedComponents/Footer/Components/investorInnovator";
import Footer from "../SharedComponents/Footer";

const Home = () => {
  const isSmall = useMediaQuery({ maxWidth: 601 });
  console.log ("home", isSmall)
  return (
    <div width="100%">
      <Header small={isSmall} />
      <Hero small={isSmall}/>
      <Transparency />
      <Promises />
      <Strategy />
      <Responsibility small={isSmall} />
      
      <InvestorInnovatorFooter />
      <Footer small={isSmall}/>
    </div>
  );
};

export default Home;
