import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import Partnerships from "../../assets/images/partnerships.svg";
import DueDilligence from "../../assets/images/due-dilligence.svg";
import Coaching from "../../assets/images/coaching.svg";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const ListItem = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Stack
        direction="row"
        spacing={3}
        alignItems="flex-start"
        textAlign="top"
      >
        <Typography variant="arizona" fontSize="44px" color="midGreen.main">
          {props.number}
        </Typography>
        <Typography
          variant="proxima700"
          fontSize={isMobile ? "28px" : "24px"}
          lineHeight="28px"
          pr="30px"
          color="darkTeal.main"
          pt="10px"
        >
          {props.label}
        </Typography>
      </Stack>
    </Grid>
  );
};
const Derisk = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="rgba(189, 244, 189, 0.25)"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      pb="24px"
    >
      <Grid container spacing={1} width="100%">
        <Grid item xs={isMobile ? 9 : 12}>
          <Typography
            variant="arizona"
            color="darkTeal.main"
            fontSize="44px"
            lineHeight="44px"
          >
            How we de-risk
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} pt="18px">
          <Typography
            variant="proxima400"
            color="darkTeal.main"
            fontSize={isMobile ? "24px" : "22px"}
            lineHeight="24px"
          >
            The Ecliptic Capital strategy de-risks your investments.
          </Typography>
        </Grid>
      </Grid>

      <Grid container columnSpacing={3} rowSpacing={5} my="56px">
        <Grid item xs={12} md={6} lg={4} >
          <Stack spacing={3} alignItems="flex-start" textAlign="top">
            <img src={Partnerships} height="44px" />
            <Typography
              variant="proxima700"
              fontSize={isMobile ? "28px" : "24px"}
              lineHeight="28px"
              pr="30px"
              color="darkTeal.main"
              pt="10px"
            >
              Form strategic partnerships with innovative founders.
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6} lg={4} >
          <Stack spacing={3} alignItems="flex-start" textAlign="top">
            <img src={DueDilligence} height="44px" />
            <Typography
              variant="proxima700"
              fontSize={isMobile ? "28px" : "24px"}
              lineHeight="28px"
              pr="30px"
              color="darkTeal.main"
              pt="10px"
            >
              Execute world-class due diligence and evaluate strengths and gaps.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4} >
          <Stack spacing={3} alignItems="flex-start" textAlign="top">
            <img src={Coaching} height="44px" />
            <Typography
              variant="proxima700"
              fontSize={isMobile ? "28px" : "24px"}
              lineHeight="28px"
              pr="30px"
              color="darkTeal.main"
              pt="10px"
            >
              Coach founders on the journey, then invest when maximum returns align.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Derisk;
