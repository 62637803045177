import React from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import Header from "../SharedComponents/header";
import Hero from "./Components/hero";
import CaseStudies from "./Components/caseStudies";
import CoCreation from "./Components/coCreation";
import Testimonials from "./Components/testimonials";
import InvestorFooter from "../SharedComponents/Footer/Components/investor";
import Footer from "../SharedComponents/Footer";
import Support from "./Components/support";

const Innovators = (props) => {
  const isSmall = useMediaQuery({ maxWidth: 601 });

  return (
    <Box>
      <Header small={isSmall} />
      <Hero small={isSmall} />
      <Support />
      <CaseStudies />
      <CoCreation />
      <Testimonials />
      <InvestorFooter />
      <Footer />
    </Box>
  );
};

export default Innovators;
