import React from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import Background from "../../assets/images/stratgy-background.svg";
import Orbit from "../../assets/images/orbit.svg";
import Grid from "@mui/material/Unstable_Grid2/";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ReactVivus from "react-vivus";

const RightMoment = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="white"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      display="flex"
      justifyContent="center"
    >
      <Grid container mb="80px">
        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
          <Box >
            <img width="80%" height="auto" src={Orbit}></img>
          </Box>
        </Grid>
        <Grid
          item
          height="100%"
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Stack
            display="flex"
           
            justifyContent={isMobile ? undefined : "center"}
            mb={isMobile ? "64px" : undefined}
            spacing={2}
          >
            <Typography
              
              mb="20px"
              variant="arizona"
              color="darkTeal.main"
              fontSize="44px"
            >
              At the exact right moment
            </Typography>
            <Typography
              variant="proxima400"
              color="darkTeal.main"
              fontSize="25px"
            >
              When we see true innovation, we pursue and nurture it. Through
              real relationships, we support and fund viable innovations for
              impactful results.
            </Typography>

            <Typography
              variant="proxima400"
              color="darkTeal.main"
              fontSize="25px"
              lineHeight="130%"
            >
              The perfect point of alignment for small risks and big returns is
              where Ecliptic invests in disruptive tech.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RightMoment;
