import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BgLeft from "../../assets/images/portfolio-bg-left.svg";
import BgRight from "../../assets/images/portfolio-bg-right.svg";
import Caret from "../../assets/images/caret-down-select.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Funnel from "../../assets/images/funnel.svg";
import XButton from "../../assets/images/x-button.svg";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import InputLabel from "@mui/material/InputLabel";
import styled from "@emotion/styled";
import { useMediaQuery } from "react-responsive";
import { createClient } from "contentful";
import config from "../../config";

const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const MyExpandMoreIcon = styled(ExpandMoreIcon)({
  color: "navy.main",
  marginRight: "20px",
  marginTop: "-5px",
  height: "30px",
  width: "30px",
  "&.MuiSelect-iconOpen": {
    marginLeft: "20px",
  },
});

const PortfolioCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="white"
      borderRadius="12px"
      height="378px"
      width="100%"
      px="28px"
      py="28px"
    >
      <Stack
        display="flex"
        justifyContent="center"
        spacing={2}
        alignItems="center"
       
      >
        <Box
          height="154px"
          width="100%"
          sx={{
            border: isMobile ? 0 : 1,
            borderBottom: isMobile ? 1 : undefined,
            borderRadius: "4px",
            color: "#E4E6F3",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={props.logo}
            height={"70%"}
            style={{ objectFit: "contain", maxWidth: "90%" }}
          />
        </Box>
        <Typography
          variant="proxima700"
          color="darkestBlue.main"
          fontSize="24px"
        >
          {props.companyName}
        </Typography>
        <Typography
          variant="proxima400"
          color="#596379"
          fontSize="14px"
          lineHeight="14px"
        >
          {props.category}
        </Typography>

        <Typography
          variant="proxima400"
          color="darkestBlue.main"
          fontSize="16px"
          textAlign="center"
        >
          {props.subtitle}
        </Typography>
        <Box sx={{ height:"100%",display:"flex", alignItems:"bottom" }}>
          <a href={props.url} target="_blank">
            <Box
              height="100%"
         
              sx={{ borderBottom: 1, color: "skyBlue.main" }}
              textAlign="bottom"
            >
              <Typography
                variant="proxima600"
                color="darkestBlue.main"
                fontSize="14px"
                
              >
                VISIT WEBSITE
              </Typography>
            </Box>
          </a>
        </Box>
      </Stack>
    </Box>
  );
};

const PortfolioList = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [portfolioTypes, setPortfolioTypes] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  const [portfolioCompanies, setPortfolioCompanies] = useState([]);

  useEffect(() => {
    fetchData();
    // console.log(articles);
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "portfolioCompany",
        order: "fields.name",
      });

      setPortfolioCompanies(resp.items);
      console.log(resp.items);
      resp = await client.getEntries({
        content_type: "portfolioType",
      });

      setPortfolioTypes(resp.items);

      console.log(resp.items);
    } catch (error) {
      console.log(error);
    }
  };

  const FilterStates = {
    IdentitySecurity: "Identity & Security",
    EnergyTransition: "Energy Transition",
    LifeSciences: "Life Sciences",
    LegacyInvestments: "Legacy Investments",
  };

  const buttonStyles = {
    height: "44px",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#322B92",
  };

  const handleFilterClick = (type) => {
    if (filterOptions.includes(type)) {
      var index = filterOptions.indexOf(type);
      if (index > -1) {
        filterOptions.splice(index, 1);
      }
    } else {
      filterOptions.push(type);
      console.log(type);
    }
    setFilterOptions([...filterOptions]);
    console.log("filterOptions", filterOptions);
  };
  const handleFilterSelectEvent = (event) => {
    setFilterOptions(event.target.value);
  };

  return (
    <Box backgroundColor="#E4E6F3">
      {isMobile ? (
        <Box width="100%" px="24px" py="30px">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="default">
              <Stack
                spacing={2}
                direction="row"
                height="100%"
                mt="10px"
                display="flex"
                alignItems="center"
                px="2px"
              >
                <img src={Funnel} />
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="navy.main"
                >
                  Filter portfolio
                </Typography>
              </Stack>
            </InputLabel>
            <Select
              IconComponent={MyExpandMoreIcon}
              label="Filter"
              multiple
              variant="standard"
              value={filterOptions}
              disableUnderline
              displayEmpty
              onChange={handleFilterSelectEvent}
              sx={{
                border: 0,
                px: "15px",
                width: "100%",
                overflow: "hidden",
                mb: "12px",
                height: "44px",
                borderRadius: "58px",
                fontFamily: "",
                backgroundColor: "white",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "145%",
              }}
            >
              {portfolioTypes.map((type) => {
                return (
                  <MenuItem key={type.sys.id} value={type.sys.id}>
                    <Typography
                      variant="proxima600"
                      fontSize="16px"
                      color="navy.main"
                    >
                      {type.fields.type}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Grid
          container
          spacing={3}
          px="56px"
          py="34px"
          justifyContent="flex-end"
        >
          {portfolioTypes.map((type) => {
            return (
              <Grid key={type.sys.id} item xs={12} md={6} lg={2.5}>
                <motion.div
                  style={{
                    ...buttonStyles,
                    color: filterOptions.includes(type.sys.id)
                      ? "#fff"
                      : "#322B92",
                    backgroundColor: filterOptions.includes(type.sys.id)
                      ? "#322B92"
                      : "#fff",
                  }}
                  whileHover={{
                    cursor: "pointer",
                    backgroundColor: "#322B92",
                    color: "#fff",
                  }}
                  onClick={() => handleFilterClick(type.sys.id)}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      variant="proxima600"
                      fontSize="16px"
                      color="inherit"
                    >
                      {type.fields.type}
                    </Typography>
                    {filterOptions.includes(type.sys.id) && (
                      <img src={XButton} height="11px"></img>
                    )}
                  </Stack>
                </motion.div>
              </Grid>
            );
          })}
        </Grid>
      )}

      <Grid
        container
        spacing={2}
        px="56px"
        pb="40px"
        sx={{
          backgroundImage: isMobile
            ? undefined
            : `url(${BgLeft}), url(${BgRight})`,
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPositionY: "top, top",
          backgroundPositionX: "left, right",
          backgroundSizeX: "128px, 128px",
        }}
      >
        {portfolioCompanies.map((company) => {
          return (
            (filterOptions.includes(company.fields.portfolioType.sys.id) ||
              !filterOptions.length) && (
              <Grid item key={company.sys.id} xs={12} md={6} lg={4}>
                <PortfolioCard
                  companyName={company.fields.name}
                  category={company.fields.portfolioType.fields.type}
                  subtitle={company.fields.description}
                  url={company.fields.url}
                  logo={"https:" + company.fields.logo.fields.file.url}
                />
              </Grid>
            )
          );
        })}
      </Grid>
    </Box>
  );
};

export default PortfolioList;
