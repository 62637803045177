import React from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import Background from "../../assets/images/stratgy-background.svg";
import Orbit from "../../assets/images/orbit.svg";
import Grid from "@mui/material/Unstable_Grid2/";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ReactVivus from "react-vivus";
import Expand from "../../assets/images/expand.svg";

import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["support", "guide", "coach", "nurture"];

const Support = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Box
      backgroundColor="white"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      display="flex"
      justifyContent="center"
    >
      <Grid container mb={isMobile ? undefined : "8w0px"}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={Expand}
            style={{
              height: isMobile ? "80%" : undefined,
              width: isMobile ? undefined : "60%",
            }}
          />
        </Grid>
        <Grid
          item
          height="100%"
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Stack
            display="flex"

            justifyContent={isMobile ? undefined : "center"}
            mb={isMobile ? "64px" : undefined}
            spacing={2}
          >
            <Typography
              variant="proxima600"
              color="darkRed.main"
              fontSize="18px"
            >
              You do you
            </Typography>

            <Typography
              mb="20px"
              variant="arizona"
              color="darkRed.main"
              fontSize="44px"
            >
              {"We "}

              <TextTransition
                inline
                style={{ textDecoration: "underline" }}
                springConfig={presets.stiff}
              >
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
              <span style={{ paddingLeft: index === 0 ? "20px" : undefined }}>
                {" your vision"}
              </span>
            </Typography>
            <Typography
              variant="proxima400"
              color="darkRed.main"
              fontSize="25px"
            >
              We support the next generation of startups. With over 100 years of
              experience, the Ecliptic team has built, scaled, financed,
              mentored, expanded, and monetized changemakers.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Support;
