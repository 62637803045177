import { appTheme } from "./theme";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Landing from "./Landing";
import Innovators from "./Innovators";
import Investors from "./Investors";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Newsroom from "./Newsroom";
import Reports from "./Reports";

import PitchForm from "./PitchForm";
import ThankYou from "./PitchForm/Components/thankYou";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";



function App() {
  return (

      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <div className="App">
          <main className="content">
            <Routes>
            <Route path="/" element={<Landing />} />
              <Route path="/home" element={<Home />} />
              <Route path="/investors" element={<Investors />} />
              <Route path="/innovators" element={<Innovators />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/team" element={<Contact />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/newsroom" element={<Newsroom />} />
              <Route path="/pitch-us" element={<PitchForm />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
  
  );
}
export default App;
