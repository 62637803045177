import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import { Link, Navigate } from "react-router-dom";
import LandingLeft from "../assets/images/landing-left.svg";
import MarkWhite from "../assets/images/mark-white.svg";
import MarkInvestors from "../assets/images/mark-investors-dark.svg";
import MarkInnovators from "../assets/images/mark-innovators-dark.svg";
import MarkEcliptic from "../assets/images/mark-ecliptic-dark.svg";
import UpRightArrow from "../assets/images/up-right-arrow.svg";
import ReactVivus from "react-vivus";
import { motion } from "framer-motion";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import DownArrow from "../assets/images/down-arrow.svg";
import DownArrowWhite from "../assets/images/down-arrow-white.svg";
import UpRightArrowBlue from "../assets/images/up-right-arrow-blue.svg";
import UpRightArrowWhite from "../assets/images/up-right-arrow-white.svg";

const styles = {
  buttonStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    height: "46px",
    border: 1,
    borderColor: "#E4E6F3",
    borderRadius: "4px",
    textDecoration: "none",
    borderStyle: "solid",
  },
};
const Landing = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [eclipticClicked, setEclipticClicked] = useState(false);

  const [initialAnimationFinished, setInitialAnimationFinished] =
    useState(true);

  const [redirect, setRedirect] = useState(false);

  const [hover, setHover] = useState();
  const [backgroundColor, setBackgroundColor] = useState("#999EC0");

  const animationFinished = () => {
    setInitialAnimationFinished(true);
  };

  const setHoverState = (state) => {
    setHover(state);

    if (state === "investor") {
      setBackgroundColor("#73E580");
    } else if (state === "ecliptic") {
      setBackgroundColor("#4FC3F7");
    } else if (state === "innovator") {
      setBackgroundColor("#FF974A");
    } else {
      setBackgroundColor("#999EC0");
    }
  };

  const circleVariants = {
    initial: {
      position: "fixed",
      width: "400%",
      height: "0%",
      top: "-100%",
      left: "-150%",
      borderRadius: "50%",
      backgroundColor: "white",
    },
    transition: {
      position: "fixed",
      width: "400%",
      height: "200%",
      top: "-50%",
      left: "-150%",
      borderRadius: "50%",
      backgroundColor: "white",
    },
  };

  const opaqueMarkVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  const markVariants = {
    visible: { opacity: 0.1 },
    hidden: { opacity: 0 },
  };
  const textVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  const backgroundVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const eclipticButtonClicked = () => {
    setEclipticClicked(true);
  };

  const animationComplete = (definition) => {
    console.log(definition);
    if (definition === "visible") {
      setRedirect(true);
    }
  };
  return (
    <Box
      width="100%"
      height="100%"
      sx={{ backgroundColor: "darkestBlue.main" }}
    >
      {redirect && <Navigate to="/home" />}
      <motion.div
        variants={circleVariants}
        animate={eclipticClicked ? "transition" : "initial"}
        transition={{ ease: "easeIn", delay: 1, duration: 0.6 }}
      ></motion.div>
      {!isMobile && (
        <motion.div
          animate={eclipticClicked ? "hidden" : "visible"}
          width="100%"
          height="100%"
          variants={backgroundVariants}
          style={{ backgroundColor: "white" }}
        >
          <ReactVivus
            id="left"
            option={{
              type: "sync",
              file: LandingLeft,
              animTimingFunction: "EASE_OUT",

              duration: 80,
              onReady: console.log,
            }}
            style={{
              height: "100%",
              width: "50%",
              stroke: backgroundColor,
              position: "absolute",
              top: 0,
              left: 0,
            }}
            callback={animationFinished}
          />
          <ReactVivus
            id="right"
            option={{
              type: "sync",
              file: LandingLeft,
              animTimingFunction: "EASE_OUT",
              duration: 80,
              onReady: console.log,
            }}
            style={{
              height: "100%",
              width: "50%",
              position: "absolute",
              stroke: backgroundColor,
              top: 0,
              right: 0,
              transform: "scaleX(-1)",
            }}
            callback={console.log}
          />{" "}
        </motion.div>
      )}
      <motion.div
        animate={eclipticClicked ? "hidden" : "visible"}
        variants={backgroundVariants}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100vh"
        >
          <motion.div
            initial="hidden"
            animate={initialAnimationFinished && !hover ? "visible" : "hidden"}
            transition={{ duration: 0.8 }}
            variants={markVariants}
            style={{
              position: "fixed",
              top: isMobile ? "35%" : "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src={MarkWhite}
              style={{ width: isMobile ? "70vw" : "30vw" }}
            />
          </motion.div>
          {hover === "investor" && (
            <Typography
              variant="proxima400"
              fontSize="20px"
              color="white"
              style={{
                position: "fixed",
                top: "75%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Learn about our investments
            </Typography>
          )}
          {hover === "ecliptic" && (
            <Typography
              variant="proxima400"
              fontSize="20px"
              color="white"
              style={{
                position: "fixed",
                top: "75%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Learn more about our company
            </Typography>
          )}
          {hover === "innovator" && (
            <Typography
              variant="proxima400"
              fontSize="20px"
              color="white"
              style={{
                position: "fixed",
                top: "75%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Find out how we support entrepreneurs and startups
            </Typography>
          )}
          <motion.div
            initial="hidden"
            animate={
              initialAnimationFinished && hover === "investor"
                ? "visible"
                : "hidden"
            }
            transition={{ duration: 0.5 }}
            variants={opaqueMarkVariants}
            style={{
              position: "fixed",
              top: isMobile ? "35%" : "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={MarkInvestors} style={{ width: "30vw" }} />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={
              initialAnimationFinished && hover === "ecliptic"
                ? "visible"
                : "hidden"
            }
            transition={{ duration: 0.5 }}
            variants={opaqueMarkVariants}
            style={{
              position: "fixed",
              top: isMobile ? "35%" : "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={MarkEcliptic} style={{ width: "30vw" }} />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={
              initialAnimationFinished &&
              hover === "innovator" &&
              !eclipticClicked
                ? "visible"
                : "hidden"
            }
            transition={{ duration: 0.5 }}
            variants={opaqueMarkVariants}
            style={{
              position: "fixed",
              top: isMobile ? "35%" : "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={MarkInnovators} style={{ width: "30vw" }} />
          </motion.div>
        </Box>
      </motion.div>

      <motion.div
        initial="hidden"
        animate={
          (initialAnimationFinished && !hover) || eclipticClicked
            ? "visible"
            : "hidden"
        }
        transition={{ delay: 0.2, duration: .5 }}
        variants={textVariants}
      >
        <Typography
          sx={{
            position: "fixed",
            top: isMobile ? "35%" : "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
          variant="arizona"
          color="white"
          fontSize={isMobile ? "46px" : "72px"}
          lineHeight={isMobile ? "52px" : "82px"}
        >
          Innovation for a better tomorrow
        </Typography>
      </motion.div>
      <motion.div
        initial="hidden"
        animate={eclipticClicked ? "visible" : "hidden"}
        transition={{ delay: 1.3, duration: 1 }}
        variants={textVariants}
        onAnimationComplete={(definition) => animationComplete(definition)}
      >
        <Typography
          sx={{
            position: "fixed",
            top: isMobile ? "35%" : "45%",
            left: "50%",

            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
          variant="arizona"
          color="navy.main"
          fontSize={isMobile ? "46px" : "72px"}
          lineHeight={isMobile ? "52px" : "82px"}
        >
         Innovation for a better tomorrow
        </Typography>
      </motion.div>
      <motion.div
        animate={eclipticClicked ? "hidden" : "visible"}
        variants={backgroundVariants}
      >
        <Stack
          direction={!isMobile ? "row" : undefined}
          container
          spacing={isMobile ? 1 : 6}
          sx={{
            position: "fixed",
            bottom: isMobile ? 0 : "5%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Link to="/investors">
            <motion.div
              onMouseEnter={() => {
                setHoverState("investor");
              }}
              onMouseLeave={() => {
                setHoverState("");
              }}
              whileHover={{
                backgroundColor: "#73E580",
                color: "#09183D",
                borderColor: "#09183D",
              }}
              style={{
                width: isMobile ? "267px" : "237px",
                ...styles.buttonStyle,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="proxima600"
                  color="inherit"
                  fontSize="24px"
                >
                  I'm an Investor
                </Typography>
                <img
                  src={
                    hover === "investor" ? UpRightArrowBlue : UpRightArrowWhite
                  }
                  style={{ height: "12px" }}
                />
              </Stack>
            </motion.div>
          </Link>

          {!isMobile && <motion.div
            onClick={eclipticButtonClicked}
            onMouseEnter={() => {
              setHoverState("ecliptic");
            }}
            onMouseLeave={() => {
              setHoverState("");
            }}
            whileHover={{
              cursor: "pointer",
              backgroundColor: "#4FC3F7",
              color: "#09183D",
              borderColor: "#09183D",
            }}
            style={{ width: "267px", ...styles.buttonStyle }}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="proxima600" color="inherit" fontSize="24px">
                Look into Ecliptic
              </Typography>
              <img
                src={hover === "ecliptic" ? DownArrow : DownArrowWhite}
                style={{ height: "10px" }}
              />
            </Stack>
          </motion.div> }

          <Link to="/innovators">
            <motion.div
              onMouseEnter={() => {
                setHoverState("innovator");
              }}
              onMouseLeave={() => {
                setHoverState("");
              }}
              whileHover={{
                cursor: "pointer",
                backgroundColor: "#FF974A",
                color: "#09183D",
                borderColor: "#09183D",
              }}
              style={{
                width: isMobile ? "267px" : "237px",
                ...styles.buttonStyle,
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="proxima600"
                  color="inherit"
                  fontSize="24px"
                >
                  I'm an Innovator
                </Typography>
                <img
                  src={
                    hover === "innovator" ? UpRightArrowBlue : UpRightArrowWhite
                  }
                  style={{ height: "12px" }}
                />
              </Stack>
            </motion.div>
          </Link>
          {isMobile && <motion.div
            onClick={eclipticButtonClicked}
            onMouseEnter={() => {
              setHoverState("ecliptic");
            }}
            onMouseLeave={() => {
              setHoverState("");
            }}
            whileHover={{
              cursor: "pointer",
              backgroundColor: "#4FC3F7",
              color: "#09183D",
              borderColor: "#09183D",
            }}
            style={{ width: "267px", ...styles.buttonStyle }}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="proxima600" color="inherit" fontSize="24px">
                Look into Ecliptic
              </Typography>
              <img
                src={hover === "ecliptic" ? DownArrow : DownArrowWhite}
                style={{ height: "10px" }}
              />
            </Stack>
          </motion.div> }
        </Stack>
      </motion.div>
    </Box>
  );
};

export default Landing;
