import React from "react";
import Header from "../SharedComponents/header";
import { Box } from "@mui/material";
import Hero from "./Components/hero";
import PeopleList from "./Components/peopleList";
import InvestorInnovatorFooter from "../SharedComponents/Footer/Components/investorInnovator";
import Footer from "../SharedComponents/Footer";
import Partners from "./Components/partners";

const Contact = () => {
  return (
    <Box>
      <Header />
      <Hero />
      <PeopleList />
      <Partners />
      <InvestorInnovatorFooter />
      <Footer />
    </Box>
  );
};

export default Contact