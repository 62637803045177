import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import { useMediaQuery } from "react-responsive";

const Hero = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="#E4E6F3"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
    >
      <Grid container spacing={isMobile ? 3: 1} width="100%">
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="navy.main"
            fontSize={isMobile ?  "48px" : "72px" }
            lineHeight={isMobile ? "56px" : "78px"} 
          >
            Our Portfolio
          </Typography>
        </Grid>
        <Grid item xs={12} pt="18px">
          <Typography
            variant="proxima400"
            color="navy.main"
            fontSize="22px"
            lineHeight="24px"
          >
            Ecliptic’s Innovator list continues to grow. Our steadfast
            management drives value—ever upward.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
