import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";

import HeroBackground from "../../assets/images/contact-hero-bg.jpg";
import LocationPin from "../../assets/images/location-pin.svg";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";

const Hero = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="rgba(255, 214, 169, 0.15)"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      sx={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundSize: "cover",
      }}
    >
      <Grid container spacing={1} width="100%">
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="white"
            fontSize={isMobile ? "48px" : "72px"}
            lineHeight={isMobile ? "56px" : "78px"}
          >
            Reach out
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8} pr={isMobile ? "22px" : "100px"} pt="18px">
          <Typography variant="proxima400" color="white" fontSize="22px">
            Want to connect with Ecliptic about your business or idea? Fill out
            the form below. If our interests align, we’ll get back to you. For
            all other inquiries, email us at{" "}
            <a
              href="mailto:inquiries@eclipticcapital.com"
              style={{ color: "white", textDecoration: "underline" }}
            >
              inquiries@eclipticcapital.com
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} pt="18px">
          <Typography variant="proxima400" color="white" fontSize="22px">
            <Stack
              direction={isMobile ? undefined : "row"}
              spacing={2}
              display="flex"
              justifyContent="center"
            >
              <Box>
                <Typography
                  display="block"
                  variant="proxima400"
                  fontSize="28px"
                  letterSpacing=".7px"
                  mb="20px"
                  mt={isMobile ? "64px" : undefined}
                >
                  <img
                    src={LocationPin}
                    style={{ height: "29px", marginRight: "20px" }}
                  />
                  Based in Austin, Texas
                </Typography>
                <Link
                  target="_blank"
                  to="https://goo.gl/maps/aGmTsErHaPpwejia9"
                >
                  <Typography
                    variant="proxima400"
                    fontSize="18px"
                    color="white"
                    sx={{
                      textDecoration: "underline",
                      textDecorationColor: "white",
                    }}
                  >
                    1209 W. 5th Street, 3rd Floor Austin, Texas 78701
                  </Typography>
                </Link>
              </Box>
            </Stack>
          </Typography>
        </Grid>
      </Grid>
      <Box mt="24px" display="flex">
        <Link to="/pitch-us">
          <Box
            height="39px"
            backgroundColor="skyBlue.main"
            width={props.small ? "100%" : "auto"}
            px="24px"
            borderRadius="4px"
            mb="120px"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                  sx={{pt:"2px"}}
                >
                  PITCH US
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Hero;
