import React from "react";
import Box from "@mui/material/Box";
import Background from "../../assets/images/co-creation-bg.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";

import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const ListItem = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        pr={isMobile ? "130px" : "50px"}
    
      >
        <Typography variant="arizona" fontSize="44px" pt="10px" color="#ED533A">
          {props.number}
        </Typography>
        <Typography
          variant="proxima700"
          fontSize="24px"
          lineHeight="28px"
          pr="30px"
          color="darkRed.main"
          pt="10px"
          whiteSpace={isMobile ? undefined : "pre-line"}
        >
          {props.label}
        </Typography>
      </Stack>
    </Grid>
  );
};
const CoCreation = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="rgba(255, 214, 169, 0.25)"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      pb="24px"
      sx={{
        backgroundImage: isMobile ? undefined : `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        backgroundPositionX: "right",
        backgroundSize: "835px 170px ",
      }}
    >
      <Grid container spacing={2} width="100%">
        <Grid item xs={12}>
          <Typography variant="proxima600" color="darkRed.main" fontSize="18px">
            The Ecliptic Way
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="darkRed.main"
            fontSize="44px"
            lineHeight="44px"
          >
            Co-creation. Options. Hands-on. Transparent.
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} pt="18px">
          <Typography
            variant="proxima400"
            color="darkRed.main"
            fontSize="22px"
       
          >
            What we do is complex, but ultimately it makes your work simpler.
          </Typography>
        </Grid>
      </Grid>

      <Grid container columnSpacing={3} rowSpacing={5} my={isMobile ? "20px" : "56px"}>
        <ListItem number="1" label={`We select\n each other.`} />

        <ListItem number="2" label={`Deep dive\n conversations.`} />
        <ListItem number="3" label={`Share valuable\n insights.`} />
        <ListItem number="4" label={`Disperse funds\n where needed.`} />
        <ListItem number="5" label={`We're comfortable\nleading or following.`} />
        <ListItem number="6" label={`Prepare you\nto scale.`} />
      </Grid>
    </Box>
  );
};

export default CoCreation;
