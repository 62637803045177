import Box from "@mui/material/Box";
import React from "react";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import StarField from "../../assets/images/star-field.jpeg";
import { Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Transparency = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const background = {
    image: StarField,
    speed: -20,
    opacity: [0.5, 0.5],
    expanded:true
  };

  const headline = {
    
    translateY: [22, 25],
    
    shouldAlwaysCompleteAnimation: true,
    expanded: true,
    children: (
      <div className="absolute inset-0 flex items-center justify-center">
        <Typography
          mx="auto"
          variant="arizona"
          fontSize={isMobile ? "24px" : "44px"}
          color="white"
          className="text-8xl text-white font-thin"
          textAlign={isMobile ? "center" : undefined}
          sx={{
            width:"80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          86% of people believe that now is more important than ever for
          businesses to operate with transparency.
        </Typography>
        <Typography
          width="100%"
          variant="arizona"
          fontSize={isMobile ? "14px" : "24px"}
          textAlign={isMobile ? "center" : undefined}
          color="white"
          className="text-8xl text-white font-thin"
          display="inline"
          mr="44px"
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "right",
          }}
        >
          —Sprout Social
        </Typography>
      </div>
    ),
  };
  return (
    <Box sx={{overflow: "hidden", height: isMobile ? "200px" : "280px" }} backgroundColor="#5048DB">
      <ParallaxBanner
        style={{ aspectRatio: "2 / 1" }}
        layers={[background, headline]}
      ></ParallaxBanner>
    </Box>
  );
};

export default Transparency;
