import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";

import HeroBackground from "../../assets/images/investors-hero-bg.svg"
import UpRightArrow from "../../assets/images/up-right-arrow-black.svg";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Hero = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="rgba(189, 244, 189, 0.15)"
      px={isMobile ? "24px" : "80px" }
      pt={isMobile ? "64px" : "84px" }
      sx={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        backgroundPositionX: "30vw",
        backgroundSize: "1600px 222px ",
      }}
    >
      <Grid container spacing={1} width="100%">
        <Grid item xs={12}>
          <Typography
            variant="proxima600"
            color="darkTeal.main"
            fontSize="18px"
          >
            Our method works
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="darkTeal.main"
            fontSize={isMobile ? "56px" : "72px" }
            lineHeight={isMobile ? "64px" : "78px"}

          >
            Seasoned operators. Active engagement. Full transparency.
          </Typography>
        </Grid>
        <Grid item xs={12} pt="18px">
          <Typography
            variant="proxima400"
            color="darkTeal.main"
           
            fontSize={isMobile ? "24px" : "22px" }
            lineHeight={isMobile ? "32px" : "28px"}
          >
            Institutional investors, family offices, and individual investors
          </Typography>
        </Grid>
      </Grid>
      <Box mt="44px" display="flex">
        <a href="mailto:greg@eclipticcapital.com">
          <Box
            height="39px"
            backgroundColor="electricGreen.main"
            width={props.small ? "100%" : "auto"}
            px="24px"
            borderRadius="4px"
            mb="160px"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                  letterSpacing=".7px"
                  sx={{pt:"2px"}}
                >
                  LET'S MEET
                </Typography>
                <img src={UpRightArrow} />
              </Stack>
            </Box>
          </Box>
        </a>
      </Box>
     
    </Box>
  );
};

export default Hero;
