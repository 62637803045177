import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Header from "../SharedComponents/header";
import HeroBackground from "../assets/images/newsroom-hero-bg.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Placeholder from "../assets/images/news-placeholder.jpeg";
import Stack from "@mui/material/Stack";
import { createClient } from "contentful";
import config from "../config";
import InvestorInnovatorFooter from "../SharedComponents/Footer/Components/investorInnovator";
import Footer from "../SharedComponents/Footer";

const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const FeaturedNewsCard = (props) => {
  return (
    <Box
      maxWidth="1440px"
      mx="auto"
      my="28px"
      borderRadius="12px"
      backgroundColor="white"
    >
      <Stack direction="row" p="17px" spacing={4}>
        <img
          src={props.image}
          height="292px"
          maxWidth="100px"
          width="50%"
          style={{ objectFit: "contain", maxWidth: "250px" }}
        ></img>
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="proxima600"
              color="navy.main"
              fontSize="14px"
              backgroundColor="#F6F7FC"
              px="10px"
              py="3px"
              sx={{
                border: 1,
                borderRadius: "4px",
                borderColor: "#E4E6F3",
              }}
            >
              FEATURED
            </Typography>
          </Box>
          <Typography
            variant="proxima400"
            color="darkestBlue.main"
            fontSize="33px"
          >
            {props.title}
          </Typography>
          <Typography
            variant="proxima400"
            color="darkestBlue.main"
            fontSize="22px"
            sx={{ flexGrow: 1 }}
          >
            {props.description}
          </Typography>
          <a href={props.url} target="_blank">
            <Box>
              <Typography
                variant="proxima600"
                color="darkestBlue.main"
                fontSize="14px"
                letterSpacing=".7px"
                sx={{ borderBottom: 1, borderColor: "skyBlue.main" }}
              >
                READ MORE
              </Typography>
            </Box>
          </a>
        </Stack>
      </Stack>
    </Box>
  );
};

const NewsCard = (props) => {
  return (
    <Box p="16px" height="452px" backgroundColor="white" borderRadius="12px">
      <Stack spacing={2} display="flex">
        <img
          src={props.image}
          height="179px"
          style={{ objectFit: "contain" }}
        ></img>
        <Box>
          <Typography
            variant="proxima700"
            color="navy.main"
            fontSize="14px"
            backgroundColor="#F6F7FC"
            px="10px"
            py="3px"
            sx={{
              border: 1,
              borderRadius: "4px",
              borderColor: "#E4E6F3",
            }}
          >
            {props.type}
          </Typography>
        </Box>
        <Typography
          variant="proxima400"
          color="darkestBlue.main"
          fontSize="25px"
          lineHeight="28px"
        >
          {props.title}
        </Typography>
        <Typography
          variant="proxima400"
          color="darkestBlue.main"
          fontSize="16px"
          lineHeight="18px"
          sx={{ flexGrow: 1 }}
        >
          {props.description}
        </Typography>
        <a href={props.url}>
          <Box>
            <Typography
              variant="proxima600"
              color="darkestBlue.main"
              fontSize="14px"
              letterSpacing=".7px"
              sx={{ borderBottom: 1, borderColor: "skyBlue.main" }}
            >
              READ MORE
            </Typography>
          </Box>
        </a>
      </Stack>
    </Box>
  );
};

const Newsroom = () => {
  const [news, setNews] = useState([]);
  const [featuredNews, setFeaturedNews] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "news",
      });

      const news = [];
      const featuredNews = [];

      resp.items.forEach((item) => {
        if (item.fields.featured) {
          featuredNews.push(item);
        } else {
          news.push(item);
        }
      });

      console.log(resp.items);

      setNews(news);
      setFeaturedNews(featuredNews);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box backgroundColor="#F6F7FC">
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="246px"
        sx={{
          backgroundColor: "navy.main",
          backgroundImage: `url(${HeroBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "90% 258px ",
        }}
      >
        <Typography variant="arizona" fontSize="72px" color="white">
          Newsroom
        </Typography>
      </Box>
      <Box px="44px">
        {featuredNews.map((newsItem) => {
          return (
            <FeaturedNewsCard
              id={newsItem.sys.id}
              title={newsItem.fields.title}
              description={newsItem.fields.description}
              url={newsItem.fields.url}
              image={"https:" + newsItem.fields.coverImage.fields.file.url}
            />
          );
        })}

        <Grid container pb="44px" spacing={2}>
          {news.map((newsItem) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <NewsCard
                  id={newsItem.sys.id}
                  title={newsItem.fields.title}
                  description={newsItem.fields.description}
                  url={newsItem.fields.url}
                  type={newsItem.fields.newsType?.fields.type}
                  image={"https:" + newsItem.fields.coverImage.fields.file.url}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <InvestorInnovatorFooter />
      <Footer />
    </Box>
  );
};

export default Newsroom;
