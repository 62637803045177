import React from "react";
import Box from "@mui/material/Box";
import Header from "../SharedComponents/header";
import Hero from "./Components/hero";
import PortfolioList from "./Components/portfolioList";
import Commitment from "./Components/commitment";
import Numbers from "./Components/numbers";
import InvestorInnovatorFooter from "../SharedComponents/Footer/Components/investorInnovator";
import Footer from "../SharedComponents/Footer";



const Portfolio = () => {
  return (
    <Box>
      <Header />
      <Hero />
      <PortfolioList />
      <Commitment />
      <Numbers />
      <InvestorInnovatorFooter />
      <Footer />
    </Box>
  );
};

export default Portfolio;
