import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";

const Numbers = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="navy.main"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      width="100vw"
    >
      <Grid container spacing={3} width="100%" pb="60px">
        <Grid item xs={12}>
          <Typography variant="proxima600" color="white" fontSize="18px">
            Our numbers tell a story
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8} mb="64px">
          <Typography
            variant="arizona"
            color="white"
            fontSize={isMobile ? "56px" : "72px"}
            lineHeight={isMobile ? "62px" : "78px"}
          >
            Ecliptic Capital finds the ideas that create big impact.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ borderTop: 1, color: "white" }}
          mb={isMobile ? "42px" : "84px"}
        >
          <Typography
            variant="proxima400"
            color="white"
            fontSize={isMobile ? "32px" : "28px"}
          >
            We connect the right people
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mb="50px">
          <Stack spacing={1}>
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "48px" : "39px"}
              lineHeight={isMobile ? "56px" : "44px"}
              whiteSpace="pre-line"
            >
              {`45+ investment\npartners`}
            </Typography>
            <Typography variant="proxima400" color="white" fontSize={isMobile ? "24px" : "19px"}>
              Whether firms or LP’s.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mb="50px">
          <Stack spacing={1}>
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "48px" : "39px"}
              lineHeight={isMobile ? "56px" : "44px"}
              whiteSpace="pre-line"
            >
              {`100+ years \nof experience`}
            </Typography>
            <Typography variant="proxima400" color="white" fontSize={isMobile ? "24px" : "19px"}>
              Combined number that our executives have worked in the field.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lgOffset={0} lg={4} mb="50px">
          <Stack spacing={1}>
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "48px" : "39px"}
              lineHeight={isMobile ? "56px" : "44px"}
              whiteSpace="pre-line"
            >
              {`25% female \nfounders`}
            </Typography>
            <Typography variant="proxima400" color="white" fontSize={isMobile ? "24px" : "19px"}>
              We’re dedicated to raising this number.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ borderTop: 1, color: "white" }} mb="84px">
          <Typography variant="proxima400" color="white" fontSize={isMobile ? "32px" : "28px"}>
            At the right time
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mb="50px">
          <Stack spacing={1}>
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "48px" : "39px"}
              lineHeight={isMobile ? "56px" : "44px"}
              whiteSpace="pre-line"
            >
              {`100% Investment`}
            </Typography>
            <Typography variant="proxima400" color="white" fontSize={isMobile ? "24px" : "19px"}>
              In pre-seed, seed, and series A.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mb="50px">
          <Stack spacing={1}>
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "48px" : "39px"}
              lineHeight={isMobile ? "56px" : "44px"}
              whiteSpace="pre-line"
            >
              {`250k-15 MM`}
            </Typography>
            <Typography variant="proxima400" color="white" fontSize={isMobile ? "24px" : "19px"}>
              In capital given to an Innovator.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lgOffset={0} lg={4} mb="50px">
          <Stack spacing={1}>
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "48px" : "39px"}
              lineHeight={isMobile ? "56px" : "44px"}
              whiteSpace="pre-line"
            >
              {`24/7`}
            </Typography>
            <Typography variant="proxima400" color="white" fontSize={isMobile ? "24px" : "19px"}>
              Accessible to our Innovators and Investors whenever they need us.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Numbers;
