import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import LifeSciences from "../../assets/images/life-sciences.svg";
import IdentitySecurity from "../../assets/images/identity-and-security.svg";
import EnergyMaterials from "../../assets/images/energy-and-materials.svg";
import HeroBackground from "../../assets/images/hero-background.svg";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Hero = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="#F6F7FC"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      width="100vw"
      sx={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "100% 317px ",
      }}
    >
      <Grid container spacing={3} width="100%">
        <Grid item xs={12}>
          <Typography variant="proxima600" color="navy.main" fontSize="18px">
            We see the point
          </Typography>
        </Grid>
        <Grid item xs={10} mb="44px">
          <Typography
            variant="arizona"
            color="navy.main"
            fontSize={isMobile ? "56px" : "72px"}
            lineHeight={isMobile ? "64px" : "78px"}
          >
            Innovation for a better tomorrow
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} pt="18px">
          <Typography
            variant="arizona"
            color="navy.main"
            fontSize={isMobile ? "36px" : "44px"}
            lineHeight={isMobile ? "44px" : "56px"}
          >
            The art of innovation investing
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="proxima400"
            color="navy.main"
            fontSize={isMobile ? "24px" : "22px"}
            lineHeight={isMobile ? "32px" : "28px"}
          >
            <p>
              There is great value in deep tech, life science, and identity &
              security. We support Innovators who disrupt multiple markets at
              once.
            </p>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} py="56px">
        <Grid item xs={12} sm={12} md={4}>
          <Box
            height="190px"
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack spacing={1} px="44px">
              <img style={{ height: "44px" }} src={EnergyMaterials}></img>
              <Typography
                fontSize="24px"
                color="violet.main"
                variant="proxima700"
              >
                Energy & Materials
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="darkestBlue.main"
              >
                Scientific advances. Climate Tech. Energy Transition. Material
                Sciences.
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height="190px"
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack spacing={1}>
              <img style={{ height: "44px" }} src={LifeSciences}></img>
              <Typography
                fontSize="24px"
                color="violet.main"
                variant="proxima700"
              >
                Life Sciences
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="darkestBlue.main"
                whiteSpace="pre-line"
              >
                {`Discovery Platforms. \n Digital Health. MedTech.`}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height="190px"
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack spacing={1}>
              <img style={{ height: "44px" }} src={IdentitySecurity}></img>
              <Typography
                fontSize="24px"
                color="violet.main"
                variant="proxima700"
              >
                Identity & Security
              </Typography>
              <Typography
                fontSize="17px"
                variant="proxima400"
                color="darkestBlue.main"
                whiteSpace="pre-line"
              >
                {`Detection. Prevention. Safeguard. \n \n`}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
