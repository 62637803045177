import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Header from "../SharedComponents/header";
import HeroBackground from "../assets/images/newsroom-hero-bg.svg";
import Typography from "@mui/material/Typography";
import Placeholder from "../assets/images/reports-placeholder.png";
import { createClient } from "contentful";
import Stack from "@mui/material/Stack";
import config from "../config";
import InvestorInnovatorFooter from "../SharedComponents/Footer/Components/investorInnovator";
import Footer from "../SharedComponents/Footer";
import { useMediaQuery } from "react-responsive";
const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const ReportsCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Box
      maxWidth="1440px"
      mx="auto"
      mb="28px"
      borderRadius="12px"
      backgroundColor="white"
    >
      <Stack
        direction={isMobile ? "column-reverse" : "row"}
        p="17px"
        spacing={4}
      >
        <Stack spacing={2} width="auto">
          <Box>
            <Typography
              backgroundColor="#F6F7FC"
              px="9px"
              py="5px"
              minWidth="auto"
              textTransform="uppercase"
              sx={{
                border: 1,
                borderRadius: "4px",
                borderColor: "#E4E6F3",
              }}
              variant="proxima700"
              color="navy.main"
              fontSize="14px"
            >
              {new Date(props.date).toLocaleDateString("en-us", {
                month: "long",
                year: "numeric",
                day: "numeric",
              })}
            </Typography>
          </Box>
          <Typography
            variant="proxima400"
            color="darkestBlue.main"
            fontSize="33px"
          >
            {props.title}
          </Typography>

          <Typography
            variant="proxima400"
            color="darkestBlue.main"
            fontSize="22px"
            sx={{ flexGrow: 1 }}
          >
            {props.description}
          </Typography>

          <a href={props.url} target="_blank">
            <Box>
              <Typography
                variant="proxima600"
                color="darkestBlue.main"
                fontSize="14px"
                letterSpacing=".7px"
                sx={{ borderBottom: 1, borderColor: "skyBlue.main" }}
              >
                GET THE REPORT
              </Typography>
            </Box>
          </a>
        </Stack>
        <img
          src={Placeholder}
          height="292px"
          width={isMobile ? "100%" : "50%"}
          style={{ objectFit: "contain" }}
        ></img>
      </Stack>
    </Box>
  );
};

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [datesByYear, setDatesByYear] = useState(new Map());

  useEffect(() => {
    fetchData();
    // console.log(articles);
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "report",
        order: "fields.publishDate",
      });

      setReports(resp.items);

      const datesByYear = new Map();

      resp.items.forEach((report) => {
        const year = new Date(report.fields.publishDate).getFullYear();
        if (datesByYear.has(year)) {
          datesByYear.get(year).push(report);
        } else {
          datesByYear.set(year, [report]);
        }
      });
      console.log(resp.items);
      console.log(datesByYear);
      setDatesByYear(datesByYear);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box backgroundColor="#F6F7FC">
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="246px"
        sx={{
          backgroundColor: "navy.main",
          backgroundImage: `url(${HeroBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "90% 258px ",
        }}
      >
        <Typography variant="arizona" fontSize="72px" color="white">
          Reports
        </Typography>
      </Box>
      <Box px="44px" pb="44px">
        {Array.from(datesByYear.entries()).map(([year, reports]) => (
          <Box key={year} mt="39px">
            <Box mb="12px">
              <Typography color="navy.main" fontSize="39px" variant="arizona">
                {year}
              </Typography>
            </Box>
            {reports.map((report) => {
              return (
                <ReportsCard
                  key={report.sys.id}
                  title={report.fields.title}
                  author={report.fields.author}
                  description={report.fields.description}
                  url={report.fields.url}
                  image={report.fields.coverImage}
                  date={report.fields.publishDate}
                />
              );
            })}
          </Box>
        ))}
      </Box>
      <InvestorInnovatorFooter />
      <Footer />
    </Box>
  );
};

export default Reports;
