import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";

import HeroBackground from "../../assets/images/innovators-hero-bg.svg";
import UpRightArrow from "../../assets/images/up-right-arrow-black.svg";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Hero = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="rgba(255, 214, 169, 0.15)"
      px={isMobile ? "24px" : "80px" }
      pt={isMobile ? "64px" : "84px" }
      sx={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        backgroundPositionX: "30vw",
        backgroundSize: "1600px 222px ",
      }}
    >
      <Grid container spacing={1} width="100%">
        <Grid item xs={12}>
          <Typography
            variant="proxima600"
            color="darkRed.main"
            fontSize="18px"
          >
            We meet you where you are
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="arizona"
            color="darkRed.main"
            fontSize={isMobile ? "56px" : "72px" }
            lineHeight={isMobile ? "64px" : "78px"}

          >
            Then we guide your growth.
          </Typography>
        </Grid>
        <Grid item xs={12} pt="18px">
          <Typography
            variant="proxima400"
            color="darkRed.main"
            fontSize={isMobile ? "24px" : "22px" }
            lineHeight={isMobile ? "32px" : "28px"}
          >
            See if Ecliptic is a good fit for you
          </Typography>
        </Grid>
      </Grid>
      <Box mt="44px" display="flex">
        <Link to="/pitch-us">
          <Box
            height="39px"
            backgroundColor="brightOrange.main"
            width={props.small ? "100%" : "auto"}
            px="24px"
            borderRadius="4px"
            mb="160px"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                >
                  PITCH US
                </Typography>
           
              </Stack>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Hero;
