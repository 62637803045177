import React from "react";
import Box from "@mui/material/Box";
import Background from "../../assets/images/stratgy-background.svg";
import VennDiagram from "../../assets/images/venn-diagram.png";
import Grid from "@mui/material/Unstable_Grid2/";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";

const Strategy = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="white"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "54px"}
      pb={isMobile ? undefined : "54px"}
      display="flex"
      justifyContent="center"
      sx={{
        backgroundImage: isMobile ? undefined : `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "130%",
        backgroundSize: isMobile ? "70% 117px " : "70% 217px ",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <img
            width="90%"
            height="auto"
            src={VennDiagram}
            sx={{ width: "70%" }}
          ></img>
        </Grid>
        <Grid
          item
          height="100%"
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Stack
            display="flex"
            justifyContent={isMobile ? undefined : "center"}
            mb={isMobile ? "64px" : undefined}
          >
            <Typography
              mb="20px"
              variant="arizona"
              textAlign={isMobile ? "center" : undefined}
              color="navy.main"
              fontSize="44px"
            >
              Our Strategy
            </Typography>
            <Typography
              variant="proxima400"
              textAlign={isMobile ? "center" : undefined}
              color="navy.main"
              fontSize="25px"
            >
              We see the alignment and make it work for everyone. Where there is
              a synchronous point of intersection—a cutting edge idea meets
              technology meets skill—we work hand-in-hand to propel it far
              beyond expectation.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Strategy;
