import React from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import Header from "../SharedComponents/header";
import Hero from "./Components/hero";
import RightMoment from "./Components/rightMoment";
import Focus from "./Components/focus";
import Derisk from "./Components/derisk";
import InnovatorFooter from "../SharedComponents/Footer/Components/innovator";
import Footer from "../SharedComponents/Footer";

const Investors = (props) => {
  const isSmall = useMediaQuery({ maxWidth: 601 });

  return (
    <Box>
      <Header small={isSmall} />
      <Hero small={isSmall} />
      <RightMoment />
      <Focus />
      <Derisk />
      <InnovatorFooter />
      <Footer />
    </Box>
  );
};

export default Investors;
