import React from "react";
import Box from "@mui/material/Box";
import Header from "../SharedComponents/header";
import Footer from "../SharedComponents/Footer";
import BgLeft from "../assets/images/portfolio-bg-left.svg";
import BgRight from "../assets/images/portfolio-bg-right.svg";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";
const PrivacyPolicy = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Box>
      <Header />
      <Box
        display="flex"
        py="80px"
        px="44px"
        sx={{
          backgroundColor: "#E4E6F3",
          backgroundImage: `url(${BgLeft}), url(${BgRight})`,
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPositionY: "top, top",
          backgroundPositionX: "left, right",
          backgroundSizeX: isMobile ? "24px 24px" : "128px, 128px",
        }}
      >
        {" "}
        <Stack
          px="44px"
          mx="auto"
          maxWidth="1080px"
          backgroundColor={isMobile ? "white" : undefined}
          borderRadius="12px"
          py="24px"
        >
          <Typography
            variant="arizona"
            fontSize="44px"
            color="darkestBlue.main"
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="proxima400"
            fontSize="19px"
            color="darkestBlue.main"
          >
            <p>Privacy Policy for Ecliptic Capital Website</p>
            <p>Effective date: 8/1/2023</p>
            <p>
              At Ecliptic Capital, we are committed to protecting the privacy
              and security of your personal information. This Privacy Policy
              outlines how we collect, use, disclose, and safeguard your data
              when you visit our website, located at https://ecliptic.capital/
              (the "Website"). By accessing or using our Website, you agree to
              the terms of this Privacy Policy. If you do not agree with this
              Policy, please do not use our Website.
            </p>
            <p>Information We Collect</p>
            <p>
              a. Personal Information: We may collect personal information that
              you provide to us voluntarily, such as your name, email address,
              phone number, and any other information you choose to submit
              through our Website's contact forms or email communications.
            </p>
            <p>
              b. Non-Personal Information: We may automatically collect
              non-personal information, such as your IP address, browser type,
              operating system, referring URLs, and website usage information
              when you visit our Website. This data is collected using cookies,
              web beacons, and other tracking technologies.
            </p>
            <p>Use of Information</p>
            <p>
              a. We use the personal information you provide to respond to your
              inquiries, process your requests, and communicate with you about
              our services.
            </p>
            <p>
              b. Non-personal information is used to analyze trends, administer
              the Website, track users' movements, and gather demographic
              information to improve the overall user experience.
            </p>
            <p>
              c. We may use your email address to send you periodic updates,
              newsletters, or marketing materials related to Ecliptic Capital.
              You may opt-out of receiving such communications at any time by
              following the instructions provided in the email.
            </p>
            <p>Cookies and Tracking Technologies</p>
            <p>
              a. Our Website uses cookies and similar tracking technologies to
              enhance user experience and gather non-personal information about
              users. You can choose to accept or decline cookies through your
              browser settings. Please note that disabling cookies may limit
              some features of our Website.
            </p>
            <p>
              b. We may also use third-party analytics services to track and
              analyze website usage. These third-party services may use cookies
              and similar technologies to collect non-personal information about
              your interactions with the Website.
            </p>
            <p>Data Sharing and Disclosure</p>
            <p>
              a. We do not sell, trade, or rent your personal information to
              third parties.
            </p>
            <p>
              b. We may also disclose your information when required by law,
              regulation, or legal process.
            </p>
            <p>Data Security</p>
            <p>
              a. Ecliptic Capital takes reasonable measures to protect your
              personal information from unauthorized access, use, or disclosure.
              However, no data transmission over the internet or electronic
              storage is entirely secure. Therefore, we cannot guarantee
              absolute security.
            </p>
            <p>External Links</p>
            <p>
              a. Our Website may contain links to external websites that are not
              operated by Ecliptic Capital. We are not responsible for the
              privacy practices of these third-party websites. Please review
              their respective privacy policies before providing any personal
              information.
            </p>
            <p>Children's Privacy</p>
            <p>
              a. Our Website is not intended for use by individuals under the
              age of 18. We do not knowingly collect personal information from
              children. If you are a parent or guardian and believe that your
              child has provided personal information on our Website, please
              contact us immediately, and we will take appropriate steps to
              remove the information.
            </p>
            <p>Changes to this Privacy Policy</p>
            <p>
              a. Ecliptic Capital may update this Privacy Policy from time to
              time. Any changes will be posted on this page, and the "Effective
              Date" at the top will be updated accordingly. We encourage you to
              review this Policy periodically for any updates.
            </p>
            <p>Contact Us</p>
            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or the use of your personal information, please
              contact us at:
            </p>
            <p>Ecliptic Capital 3571 FAR WEST BOULEVARD #36 AUSTIN, TX 78731</p>
            <p>Attn: Jim Puckett or Adam Lipman</p>
            <p>
              By using the Ecliptic Capital Website, you agree to the terms
              outlined in this Privacy Policy. Thank you for entrusting us with
              your personal information.
            </p>
          </Typography>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
