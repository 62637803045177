import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const colors = () => ({
  pink: "#F7CBAD",
  blue: "#2D457C",
  olive: "#555433",
  lightOlive: "#BBB69B",
  cream: "#FBF8EA",
  white: "FFFFFF",
});

// mui theme settings
export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#09183D',
    },
    secondary: {
      main: '#09183D',
    },
    darkestBlue: {
      main: "#09183D",
    },
    skyBlue: {
      main: "#4FC3F7",
    },
    navy: {
      main: "#322B92",
    },
    violet: {
      main: "#5048DB",
    },
    electricGreen: {
      main: "#73E580",
    },
    brightOrange: {
      main: "#FF974A",
    },
    darkTeal: {
      main: "#0B5364",
    },
    darkGreen: {
      main: "#008477",
    },
    midGreen: {
      main: "#33AF7C",
    },
    darkRed: {
      main:"#791229"
    },
    red: {
      main:"#BA242F"
    }

  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: "proxima_novaregular",
    fontSize: 12,
    arizona: {
      fontFamily: "abc_arizona_mixlight",
      fontStyle: "light",

    },
    proxima300: {
      fontFamily: "proxima_novaregular",
      fontStyle: "normal",
      fontSize: "38px",
      fontWeight:"300",
      lineHeight:"130%",
    },
    proxima400: {
      fontFamily: "proxima_novaregular",
      fontStyle: "normal",
      fontSize: "38px",
      fontWeight:"400",
      lineHeight:"130%",
    },
    proxima600: {
      fontFamily: "proxima_novaregular",
      fontStyle: "normal",
      fontSize: "38px",
      fontWeight:"600",
      lineHeight:"130%",
    },
    proxima700: {
      fontFamily: "proxima_novaregular",
      fontStyle: "normal",
      fontSize: "38px",
      fontWeight:"700",
      lineHeight:"130%",
    },
    h3: {
      fontFamily: "BN Nautic Grotesk",
      fontStyle: "normal",

      fontSize: "28px",
      lineHeight: "27px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
    },
    hero: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "40px",
      lineHeight: "115%",
    },
    paragraphSans: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "125%",
    },
    paragraphSansLarge: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "28px",
      lineHeight: "125%",
    },
    paragraphMono: {
      fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "145%",
    },
    paragraphMonoLarge: {
      fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "36px",
      lineHeight: "135%",
    },
    paragraphMonoSmall: {
      fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "145%",
    },
  },
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
  },
});

export const blueBox = {
  border: 1,
  borderColor: "blue.main",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign:"center"
};

export const pinkBox = {
  border: 1,
  borderColor: "pink.main",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign:"center"
};

