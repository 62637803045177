import React from "react";
import Box from "@mui/material/Box";
import Header from "../SharedComponents/header";
import Footer from "../SharedComponents/Footer";
import BgLeft from "../assets/images/portfolio-bg-left.svg";
import BgRight from "../assets/images/portfolio-bg-right.svg";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";
const TermsOfService = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Box>
      <Header />
      <Box
        display="flex"
        py="80px"
        px="44px"
        sx={{
          backgroundColor: "#E4E6F3",
          backgroundImage: `url(${BgLeft}), url(${BgRight})`,
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPositionY: "top, top",
          backgroundPositionX: "left, right",
          backgroundSizeX: isMobile ? "24px 24px" : "128px, 128px",
        }}
      >
        {" "}
        <Stack px="44px" mx="auto" maxWidth="1080px" backgroundColor={isMobile ? "white" : undefined } borderRadius="12px"  py="24px">
          <Typography
            variant="arizona"
            fontSize="44px"
            color="darkestBlue.main"
          >
            Terms of Service
          </Typography>
          <Typography
            variant="proxima400"
            fontSize="19px"
            color="darkestBlue.main"
          >
            <p>Terms of Service for Ecliptic Capital Website</p>
            <p>Effective date: 8/1/2023</p>
            <p>
              Welcome to the Ecliptic Capital website, located at
              ECLIPTIC.CAPITAL (the "Website"). By accessing or using our
              Website, you agree to be bound by the following Terms of Service.
              If you do not agree with these terms, please do not use our
              Website.
            </p>
            <p>1. Acceptance of Terms</p>
            <p>
              a. These Terms of Service constitute a legal agreement between you
              and Ecliptic Capital. By using our Website, you acknowledge that
              you have read, understood, and agree to be bound by these terms
              and any applicable laws and regulations.
            </p>
            <p>
              b. Ecliptic Capital reserves the right to modify, update, or
              replace these Terms of Service at any time, without prior notice.
              It is your responsibility to review this page periodically to stay
              informed of any changes. Your continued use of the Website after
              any modifications will constitute your acceptance of the updated
              terms.
            </p>
            <p>2. Use of the Website</p>
            <p>
              a. You must be at least 18 years old to access and use our
              Website.
            </p>
            <p>
              b. You agree to use the Website only for lawful purposes and in
              compliance with all applicable laws and regulations.
            </p>
            <p>
              c. You are responsible for maintaining the confidentiality of your
              account information, including your username and password. You
              agree to notify us immediately of any unauthorized access to or
              use of your account.
            </p>
            <p>
              d. Ecliptic Capital reserves the right to suspend or terminate
              your access to the Website at any time and for any reason,
              including if we believe that you have violated these Terms of
              Service.
            </p>
            <p>3. Intellectual Property</p>
            <p>
              a. All content on the Ecliptic Capital Website, including but not
              limited to text, graphics, logos, images, videos, and software, is
              the property of Ecliptic Capital or it's respective portfolio
              companies and is protected by intellectual property laws.
            </p>
            <p>
              b. You may not reproduce, distribute, modify, display, or create
              derivative works of any content from our Website without the prior
              written consent of Ecliptic Capital.
            </p>
            <p>
              c. Any trademarks, service marks, or logos displayed on the
              Website are the property of their respective owners and may not be
              used without their written permission.
            </p>
            <p>4. Disclaimer of Warranties</p>
            <p>
              a. The information and materials provided on the Website are for
              informational purposes only and do not constitute financial,
              investment, or legal advice.
            </p>
            <p>
              b. Ecliptic Capital does not guarantee the accuracy, completeness,
              or reliability of the content on the Website. Any reliance on such
              information is at your own risk.
            </p>
            <p>
              c. The use of our Website is at your sole discretion and risk.
              Ecliptic Capital disclaims all warranties, whether express or
              implied, including but not limited to merchantability, fitness for
              a particular purpose, and non-infringement.
            </p>
            <p>5. Limitation of Liability</p>
            <p>
              a. Ecliptic Capital and its officers, directors, employees,
              agents, and affiliates shall not be liable for any direct,
              indirect, incidental, special, or consequential damages arising
              from the use or inability to use the Website, even if advised of
              the possibility of such damages.
            </p>
            <p>
              b. In jurisdictions where limitations on liability are not
              allowed, our liability shall be limited to the maximum extent
              permitted by law.
            </p>
            <p>6. External Links</p>
            <p>
              a. The Ecliptic Capital Website may contain links to external
              websites or resources that are not owned or controlled by Ecliptic
              Capital. We are not responsible for the content or practices of
              these third-party websites. Accessing these links is at your own
              risk, and you should review their respective terms and policies.
            </p>
            <p>7. Indemnification</p>
            <p>
              a. You agree to indemnify and hold harmless Ecliptic Capital, its
              officers, directors, employees, and agents from any claims,
              losses, liabilities, damages, expenses, or demands, including
              reasonable attorneys' fees, arising out of or related to your use
              of the Website or any violation of these Terms of Service.
            </p>
            <p>8. Governing Law and Jurisdiction</p>
            <p>
              a. These Terms of Service shall be governed by and construed in
              accordance with the laws of Texas, without regard to its conflicts
              of law principles.
            </p>
            <p>
              b. Any legal action or proceeding arising out of or related to
              these Terms of Service shall be brought exclusively in the federal
              or state courts located in Texas, and you consent to the personal
              jurisdiction of such courts.
            </p>
            <p>9. Entire Agreement</p>
            <p>
              a. These Terms of Service constitute the entire agreement between
              you and Ecliptic Capital concerning the subject matter herein and
              supersede all prior or contemporaneous communications and
              proposals, whether electronic, oral, or written.
            </p>
            <p>
              If you have any questions or concerns regarding these Terms of
              Service or the use of the Ecliptic Capital Website, please contact
              us at:
            </p>
            <p>
              Ecliptic Capital 3571 FAR WEST BOULEVARD #36 AUSTIN, TX 78731
              Attn: Jim Puckett or Adam Lipman
            </p>
          </Typography>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default TermsOfService;
