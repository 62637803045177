import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UpRightArrow from "../assets/images/up-right-arrow-blue.svg";
import MarkInnovators from "../assets/images/mark-innovators-small.svg";
import MarkEcliptic from "../assets/images/mark-small.svg";
import MarkInvestors from "../assets/images/mark-investors-small.svg";
import Portfolio from "../assets/images/portfolio.svg";
import Newsroom from "../assets/images/newsroom.svg";
import Team from "../assets/images/contact.svg";
import Reports from "../assets/images/reports.svg";
import Portal from "../assets/images/portal.svg";
import Indicator from "../assets/images/indicator.png";
import CaretDown from "../assets/images/caret-down.svg";
import CaretRight from "../assets/images/caret-right.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Hamburger from "../assets/images/hamburger.svg";
import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const { pathname } = location;

  const styles = {
    header: {
      height: "78px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderColor: "#E4E6F3",
      borderBottom: "solid #E4E6F3",
      padding: isMobile ? "0 17px" : "0 80px",
    },
    logo: {
      marginRight: "20px",
    },
    navigation: {
      display: "flex",
      alignItems: "center",
    },
    navList: {
      display: "flex",
      listStyle: "none",
      margin: 0,
      padding: 0,
    },
    navItem: {
      margin: "0 10px",
      cursor: "pointer",
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: isMobile ? "bottom" : "bottom",
          horizontal: isMobile ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "right" : "left",
        }}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        <Link to="/home">
          <MenuItem
            disableRipple
            width="100%"
            height="100%"
            onClick={handleClose}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <img src={MarkEcliptic} height="44px" alt="Logo" />

              {pathname === "/home" && (
                <img
                  src={Indicator}
                  width="10px"
                  height="10px"
                  style={{ marginBottom: "5px", marginRight: "10px" }}
                />
              )}
            </Stack>
          </MenuItem>
        </Link>
        <Link to="/investors">
          <MenuItem disableRipple onClick={handleClose}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <img src={MarkInvestors} height="44px" alt="Logo" />

              {pathname === "/investors" && (
                <img
                  src={Indicator}
                  width="10px"
                  height="10px"
                  style={{ marginBottom: "5px", marginRight: "10px" }}
                />
              )}
            </Stack>
          </MenuItem>
        </Link>
        <Link to="/innovators">
          <MenuItem disableRipple onClick={handleClose}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <img src={MarkInnovators} height="44px" alt="Logo" />

              {pathname === "/innovators" && (
                <img
                  src={Indicator}
                  width="10px"
                  height="10px"
                  style={{ marginBottom: "5px", marginRight: "10px" }}
                />
              )}
            </Stack>
          </MenuItem>
        </Link>
        {isMobile && (
          <Stack px="20px" spacing={2}>
            <Divider />

            <Link to="/portfolio">
              <Stack direction="row" spacing={2}>
                <img src={Portfolio} />
                <Typography
                  variant="proxima600"
                  fontSize="24px"
                  color="darkestBlue.main"
                  sx={{ flexGrow: 1 }}
                >
                  Portfolio
                </Typography>
                <img src={CaretRight} />
              </Stack>
            </Link>

            <Link to="/newsroom">
              <Stack direction="row" spacing={2}>
                <img src={Newsroom} />
                <Typography
                  variant="proxima600"
                  fontSize="24px"
                  color="darkestBlue.main"
                  sx={{ flexGrow: 1 }}
                >
                  Newsroom
                </Typography>
                <img src={CaretRight} />
              </Stack>
            </Link>
            <Link to="/reports">
              <Stack direction="row" spacing={2}>
                <img src={Reports} />
                <Typography
                  variant="proxima600"
                  fontSize="24px"
                  color="darkestBlue.main"
                  sx={{ flexGrow: 1 }}
                >
                  Reports
                </Typography>
                <img src={CaretRight} />
              </Stack>
            </Link>
            <Link to="/team">
              <Stack direction="row" spacing={2}>
                <img src={Team} />
                <Typography
                  variant="proxima600"
                  fontSize="24px"
                  color="darkestBlue.main"
                  sx={{ flexGrow: 1 }}
                >
                  Team
                </Typography>
                <img src={CaretRight} />
              </Stack>
            </Link>
            <a href="https://portal.ecliptic.capital">
              <Stack direction="row" spacing={1}>
                <img src={Portal} />
                <Typography
                  variant="proxima600"
                  fontSize="24px"
                  color="darkestBlue.main"
                  sx={{ flexGrow: 1 }}
                >
                  Portal Login
                </Typography>
                <img
                  src={UpRightArrow}
                  style={{ path: { fill: "darkestBlue.main" } }}
                />
              </Stack>
            </a>
          </Stack>
        )}
      </Menu>

      <header style={styles.header}>
        {isMobile ? (
          <Stack
            alignItems="center"
            width="100%"
            direction="row"
            justifyContent="space-between"
          >
            {pathname !== "/investors" && pathname !== "/innovators" && (
              <img src={MarkEcliptic} height="44px" alt="Logo" />
            )}
            {pathname === "/investors" && (
              <img src={MarkInvestors} height="44px" alt="Logo" />
            )}
            {pathname === "/innovators" && (
              <img src={MarkInnovators} height="44px" alt="Logo" />
            )}
            <Button
              disableRipple
              disableFocusRipple
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                background: "none",
                border: "none",
                margin: 0,
                padding: 0,
                cursor: "pointer",
                height: "64px",
              }}
              id="menu-button"
              aria-controls={open ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src={Hamburger} height="24px" alt="Logo" />
            </Button>
          </Stack>
        ) : (
          <Button
            disableRipple
            disableFocusRipple
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
              background: "none",
              border: "none",
              margin: 0,
              padding: 0,
              cursor: "pointer",
              height: "64px",
            }}
            id="menu-button"
            aria-controls={open ? "menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              {pathname !== "/investors" && pathname !== "/innovators" && (
                <img src={MarkEcliptic} height="44px" alt="Logo" />
              )}
              {pathname === "/investors" && (
                <img src={MarkInvestors} height="44px" alt="Logo" />
              )}
              {pathname === "/innovators" && (
                <img src={MarkInnovators} height="44px" alt="Logo" />
              )}

              <img src={CaretDown} style={{ height: "9px" }} />
            </Stack>
          </Button>
        )}
        {!isMobile && (
          <nav style={styles.navigation}>
            <Stack direction="row" spacing={5}>
              <Link to="/portfolio">
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                  sx={{
                    textDecoration:
                      pathname === "/portfolio" ? "underline" : undefined,
                    textDecorationColor: "#4FC3F7",
                    textUnderlineOffset: "5px",
                    textDecorationThickness: "2px"
                  }}
                >
                  Portfolio
                </Typography>
              </Link>

              <Link to="/newsroom">
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                  sx={{
                    textDecoration:
                      pathname === "/newsroom" ? "underline" : undefined,
                    textDecorationColor: "#4FC3F7",
                    textUnderlineOffset: "5px",
                    textDecorationThickness: "2px"
                  }}
                >
                  Newsroom
                </Typography>
              </Link>
              <Link to="/reports">
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                  sx={{
                    textDecoration:
                      pathname === "/reports" ? "underline" : undefined,
                    textDecorationColor: "#4FC3F7",
                    textUnderlineOffset: "5px",
                    textDecorationThickness: "2px"
                  }}
                >
                  Reports
                </Typography>
              </Link>
              <Link to="/team">
                <Typography
                  variant="proxima600"
                  fontSize="16px"
                  color="darkestBlue.main"
                  sx={{
                    textDecoration:
                      pathname === "/team" ? "underline" : undefined,
                    textDecorationColor: "#4FC3F7",
                    textUnderlineOffset: "5px",
                    textDecorationThickness: "2px"
                  }}
                >
                  Team
                </Typography>
              </Link>
              <a href="https://portal.ecliptic.capital">
                <Stack direction="row" spacing={1}>
                  <Typography
                    variant="proxima600"
                    fontSize="16px"
                    color="darkestBlue.main"
                  >
                    Login
                  </Typography>
                  <img
                    src={UpRightArrow}
                    style={{ path: { fill: "darkestBlue.main" } }}
                  />
                </Stack>
              </a>
            </Stack>
          </nav>
        )}
      </header>
    </>
  );
};

export default Header;
