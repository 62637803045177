import React from "react";
import Box from "@mui/material/Box";
import Header from "../../SharedComponents/header";
import Footer from "../../SharedComponents/Footer";
import ThankYouHeader from "../../assets/images/thank-you.svg";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const ThankYou = () => {
  return (
    <>
      <Header />
      <Box height="auto" py="60px" width="100vw" backgroundColor="#E4E6F3">
        <Box
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="595px"
          width="80%"
          backgroundColor="white"
          borderRadius="12px"
        >
          <Stack display="flex" alignItems="center" spacing={3}>
            <img src={ThankYouHeader} height="175px" />
            <Typography variant="arizona" fontWeight="300" fontSize="44px" mx="40px">
              Thank you!
            </Typography>
            <Typography variant="proxima400" fontSize="22px" mx="40px">
              Your submission has been received and Ecliptic Capital will get
              back to you soon with feedback.
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ThankYou;
