import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/";
import Typography from "@mui/material/Typography";
import Eye from "../../assets/images/eye.svg";
import Users from "../../assets/images/users.svg";
import Cube from "../../assets/images/cube.svg";
import Rocket from "../../assets/images/rocket.svg";
import Leaf from "../../assets/images/leaf.svg";
import Stack from "@mui/material/Stack";
import Background from "../../assets/images/promises-background.svg";
import { useMediaQuery } from "react-responsive"
const Promises = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="navy.main"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      pb={isMobile ? undefined : "64px"}
      sx={{
        backgroundImage: isMobile ? undefined : `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        backgroundPositionX: "130%",
        backgroundSize: isMobile ? "70% 117px " : "70% 217px ",
      }}
    >
      <Grid container columnSpacing={4} rowSpacing={2} width="100%">
        <Grid item xs={12}>
          <Typography variant="proxima600" color="white" fontSize="18px">
            Our Promises
          </Typography>
        </Grid>
        <Grid item xs={12} mb="55px">
          <Typography
            variant="arizona"
            color="white"
            fontSize={isMobile ? "48px" : "44px"}
            lineHeight={isMobile ? "56px" : "48px"}
          >
            Ecliptic’s guide to do it right.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={0} mb={isMobile ? "69px" : undefined}>
            <Box mb="5px">
              <img src={Leaf} style={{ height: isMobile ? "30px" : "21px" }} />
            </Box>

            <Typography
              mb="11px"
              fontSize={isMobile ? "28px" : "25px"}
              variant="proxima400"
              color="white"
            >
              <b>Sustainable</b> growth only
            </Typography>
            <Typography
              mb="20px"
              fontSize={isMobile ? "20px" : "19px"}
              variant="proxima400"
              color="white"
            >
              We build durable relationships and partner on actionable,
              world-changing startups.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={0} mb={isMobile ? "69px" : undefined}>
            <Box mb="5px">
              <img src={Rocket} style={{ height: isMobile ? "30px" : "21px" }} />
            </Box>

            <Typography
              mb="11px"
              fontSize={isMobile ? "28px" : "25px"}
              variant="proxima400"
              color="white"
            >
              We are <b>Disciplined</b>
            </Typography>
            <Typography
              mb="20px"
              fontSize={isMobile ? "20px" : "19px"}
              variant="proxima400"
              color="white"
            >
              As former operators we help innovators from funding to launching,
              from culture to strategy, and the results speak for themselves.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={0} mb={isMobile ? "69px" : undefined}>
            <Box mb="5px">
              <img src={Cube} style={{ height: isMobile ? "30px" : "21px" }} />
            </Box>

            <Typography
              mb="11px"
              fontSize={isMobile ? "28px" : "25px"}
              variant="proxima400"
              color="white"
            >
              We believe in <b>Transparency</b>
            </Typography>
            <Typography
              mb="20px"
              fontSize={isMobile ? "20px" : "19px"}
              variant="proxima400"
              color="white"
            >
              Always open about our portfolio and pipeline, you know as much as
              we do every step of the way.
            </Typography>
          </Stack>
        </Grid>
        <Grid mb="20px" item xs={12} md={6} lg={4}>
          <Stack spacing={0} mb={isMobile ? "69px" : undefined}>
            <Box mb="5px">
              <img src={Users} style={{ height: isMobile ? "30px" : "21px" }} />
            </Box>

            <Typography
              mb="11px"
              fontSize={isMobile ? "28px" : "25px"}
              variant="proxima400"
              color="white"
            >
              We operate in a <b>Big Tent</b>
            </Typography>
            <Typography
              mb="20px"
              fontSize={isMobile ? "20px" : "19px"}
              variant="proxima400"
              color="white"
            >
              All voices are welcome. Solving the massive challenges we tackle
              takes an entire ecosystem.
            </Typography>
          </Stack>
        </Grid>
        <Grid mb="20px" item xs={12} md={6} lg={4}>
          <Stack spacing={0} mb={isMobile ? "69px" : undefined}>
            <Box mb="5px">
              <img src={Eye} style={{ width: isMobile ? "32px" : "23px" }} />
            </Box>

            <Typography
              mb="11px"
              fontSize={isMobile ? "28px" : "25px"}
              variant="proxima400"
              color="white"
            >
              <b>Patient</b> capital and vision
            </Typography>
            <Typography
              mb="20px"
              fontSize={isMobile ? "20px" : "19px"}
              variant="proxima400"
              color="white"
            >
              We’re in it for the long-term and build partnerships that stand
              the test of time with our Investors and our Innovators.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Promises;
