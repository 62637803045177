import React from "react";
import Header from "../SharedComponents/header";
import Footer from "../SharedComponents/Footer";
const PitchForm = () => {
  return (
    <>
      <Header />
      <iframe
        class="airtable-embed"
        src="https://airtable.com/embed/appXhgJMRjFIntRhe/shrHmiH1zbaFYqyh0?backgroundColor=blue"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="6330"
        style={{background: "transparent", border: "1px solid #ccc", overflow:"hidden"}}
      ></iframe>
      <Footer />
    </>
  );
};

export default PitchForm;
