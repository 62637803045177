import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Background from "../../assets/images/responsibility-background.png";
import Grid from "@mui/material/Unstable_Grid2/";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { createClient } from "contentful";
import config from "../../config";

const { space, environment, accessToken } = config;

const client = createClient({
  space: space,
  environment: environment, // defaults to 'master' if not set
  accessToken: accessToken,
});

const Responsibility = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [portfolioCompanies, setPortfolioCompanies] = useState([]);

  useEffect(() => {
    fetchData();
    // console.log(articles);
  }, []);

  const fetchData = async () => {
    try {
      var resp = await client.getEntries({
        content_type: "portfolioCompany",
      });
      const companies = [];
      console.log(resp.items);
      resp.items.forEach((item) => {
        if (item.fields.featured && item.fields.logoWhite) {
          companies.push(item);
          const img = new Image();
          img.src = "https:" + item.fields.logoWhite.fields.file.url
        }
      });

      setPortfolioCompanies(companies);
      setShowPortfolio(true);
    } catch (error) {
      console.log(error);
    }
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    useTransfor:false,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  return (
    <Box
      backgroundColor="#322B92"
      justifyContent="center"
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingBottom: "100px",
      }}
    >
      <Stack>
        <Grid
          px={isMobile ? "24px" : "80px"}
          pt={isMobile ? "64px" : "84px"}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="proxima600" color="white" fontSize="18px">
              Responsibility and Impact
            </Typography>
          </Grid>
          <Grid item xs={12} mb="55px">
            <Typography
              variant="arizona"
              color="white"
              fontSize={isMobile ? "36px" : "44px"}
              lineHeight="48px"
            >
              With great responsibility—to our community, ideas, and
              advancements—we make great impact.
            </Typography>
          </Grid>
        </Grid>
        <Box mt="40px" width="100%">
          {showPortfolio && (
            <Slider {...settings}>
              {portfolioCompanies.map((company) => {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        height="154px"
                        width="323px"
                        src={
                          "https:" + company.fields.logoWhite.fields.file.url
                        }
                      ></img>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </Box>
      </Stack>

      <Link
        to="/portfolio"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "3px",
        }}
      >
        <Typography
          height="39px"
          backgroundColor="skyBlue.main"
          width={isMobile ? "100%" : "250px"}
          mt="80px"
          mx={isMobile ? "31px" : "44px"}
          mb={isMobile ? "64px" : undefined}
          borderRadius="4px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          variant="proxima600"
          fontSize="16px"
          color="darkestBlue.main"
          letterSpacing=".7px"
          sx={{pt:"2px"}}
        >
          VIEW OUR FULL PORTFOLIO
        </Typography>
      </Link>
    </Box>
  );
};

export default Responsibility;
