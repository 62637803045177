import React from "react";
import Box from "@mui/material/Box";
import Background from "../../../assets/images/footer-accessory-background.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import UpRightArrow from "../../../assets/images/up-right-arrow-black.svg";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const InvestorFooter = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      backgroundColor="rgba(189, 244, 189, 0.25)"
      px={isMobile ? "24px" : "80px"}
      pt={isMobile ? "64px" : "84px"}
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
      }}
    >
      <Grid container spacing={1} width="100%">
        <Grid
          item
          xs={isMobile ? 12 : 8}
          display={isMobile ? "flex" : undefined}
          justifyContent={isMobile ? "center" : undefined}
        >
          <Typography
            variant="arizona"
            color="electricGreen.main"
            fontSize="44px"
            lineHeight="50px"
            textAlign={isMobile ? "center" : undefined}
          >
            Ecliptic Investors
          </Typography>
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 8}
          display={isMobile ? "flex" : undefined}
          justifyContent={isMobile ? "center" : undefined}
        >
          <Typography
            variant="proxima400"
            color="electricGreen.main"
            fontSize="22px"
            lineHeight="24px"
            pt="18px"
            textAlign={isMobile ? "center" : undefined}
          >
            Curious about our investment partnerships?
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4} display="flex" justifyContent="center">
          <Link to="/investors"  width="100%" style={{ marginTop: "44px" }}>
            <Box
              height="39px"
              backgroundColor="electricGreen.main"
              width={isMobile ? "100%" : "auto"}
              px="24px"
              borderRadius="4px"
              mb={isMobile ? "64px" : "100px"}
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="proxima600"
                    fontSize="16px"
                    color="darkestBlue.main"
                    letterSpacing=".7px"
                    sx={{pt:"2px"}}
                  >
                    LEARN MORE
                  </Typography>
                  <img src={UpRightArrow} style={{ width: "12px" }} />
                </Stack>
              </Box>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestorFooter;
