import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Stack from "@mui/material/Stack";
import LogoHorizontal from "../../assets/images/mark-horizontal-white.svg";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Submit from "../../assets/images/submit-button.svg";
import MarkMobile from "../../assets/images/mark-footer-mobile.svg";
import UpRightArrow from "../../assets/images/up-right-arrow.svg";
import Twitter from "../../assets/images/twitter.svg";
import Medium from "../../assets/images/medium.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import { useMediaQuery } from "react-responsive";
import jsonp from "jsonp";
import queryString from "query-string";

const NewsLetter = (props) => {
  const [mailSubmissionStep, setMailSubmissionStep] = useState();
  const [contactEmail, setContactEmail] = useState("");

  const emailSignUp = () => {
    if (contactEmail) {
      setMailSubmissionStep("sending");
      const formData = { EMAIL: contactEmail };

      jsonp(
        `//8808b7724b61355fcac32ab05.us4.list-manage.com/subscribe/post-json?u=8808b7724b61355fcac32ab05&amp;id=a74d9df74d&amp;f_id=006d67ebf0&${queryString.stringify(
          formData
        )}`,
        { param: "c" },
        (err, data) => {
          console.log(err);
          console.log(data);
        }
      );
      setMailSubmissionStep("done");
    }
  };
  return mailSubmissionStep !== "done" ? (
    <Box
      height={props.mobile ? "68px" : "47px"}
      sx={{ border: 1, borderColor: "#596379", borderRadius: "3px" }}
    >
      <Stack
        height="100%"
        direction="row"
        backgroundColor="darkestBlue.main"
        alignItems="center"
        pl="12px"
        pr="5px"
        width="100%"
      >
        <input
          type="text"
          onChange={(e) => setContactEmail(e.target.value)}
          style={{
            margin: "7px",
            width: "200px",
            border: "none",
            backgroundColor: "inherit",
            outline: "none",
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontSize: "16px",
            color: "white",
            fontWeight: "400",
          }}
          placeholder="Sign up for our newsletter"
        ></input>

        {props.mobile ? (
          <Box height="52px" onClick={() => emailSignUp()}>
            <img src={Submit} />
          </Box>
        ) : (
          <Box
            height="36px"
            backgroundColor="skyBlue.main"
            width={props.small ? "100%" : "auto"}
            px="24px"
            borderRadius="4px"
            sx={{ cursor: "pointer" }}
            onClick={() => emailSignUp()}
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="proxima600"
                fontSize="16px"
                color="darkestBlue.main"
                letterSpacing=".7px"
                sx={{ pt: "2px" }}
              >
                {mailSubmissionStep === "sending" ? "SENDING" : "SUBMIT"}
              </Typography>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  ) : (
    <Box
      height={props.mobile ? "68px" : "47px"}
      display="flex"
      alignItems="center"
    >
      <Typography variant="proxima400" color="white" fontSize="18px" pt="7px">
        Thank you for signing up!
      </Typography>
    </Box>
  );
};

const Footer = (props) => {
  const isSmall = useMediaQuery({ maxWidth: 601 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box px="56px" backgroundColor="darkestBlue.main">
      <Stack
        pt="56px"
        pb={isMobile ? "20px" : "50px"}
        direction={isMobile ? undefined : "row"}
        justifyContent="space-between"
        width="100%"
      >
        {isMobile ? (
          <img
            src={MarkMobile}
            style={{ height: "60px", marginBottom: "32px" }}
          ></img>
        ) : (
          <img src={LogoHorizontal} style={{ height: "60px" }}></img>
        )}
        <Typography
          variant="arizona"
          color="white"
          textAlign="center"
          fontSize={isMobile ? "36px" : "33px"}
        >
          Invest in innovation
        </Typography>
        {!isMobile && <NewsLetter mobile={isMobile} />}
      </Stack>
      <Box
        width="100%"
        height="1px"
        mr="225px"
        sx={{ opacity: "25%", borderBottom: 1, color: "white" }}
      ></Box>
      <Grid container pb="27px" pt="27px" spacing={isMobile ? 4 : 0}>
        <Grid
          item
          xs={12}
          md={4}
          justifyContent={isMobile ? "center" : undefined}
          display={isMobile ? "flex" : undefined}
        >
          <Stack spacing={isMobile ? 0 : 2}>
            <Link
              to="/investors"
              style={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <Typography
                variant="proxima600"
                color="white"
                fontSize={isMobile ? "24px" : "16px"}
              >
                Investors
              </Typography>
            </Link>
            <Link
              to="/innovators"
              style={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <Typography
                variant="proxima600"
                color="white"
                fontSize={isMobile ? "24px" : "16px"}
              >
                Innovators
              </Typography>
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          justifyContent={isMobile ? "center" : undefined}
          display={isMobile ? "flex" : undefined}
        >
          <Stack spacing={isMobile ? 0 : 2}>
            <Link
              to="/portfolio"
              style={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <Typography
                variant="proxima600"
                color="white"
                fontSize={isMobile ? "24px" : "16px"}
              >
                Portfolio
              </Typography>
            </Link>
            <Link
              to="/newsroom"
              style={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <Typography
                variant="proxima600"
                color="white"
                fontSize={isMobile ? "24px" : "16px"}
              >
                Newsroom
              </Typography>
            </Link>
            <Link
              to="/reports"
              style={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <Typography
                variant="proxima600"
                color="white"
                fontSize={isMobile ? "24px" : "16px"}
              >
                Reports
              </Typography>
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          justifyContent={isMobile ? "center" : undefined}
          display={isMobile ? "flex" : undefined}
        >
          <Stack spacing={isMobile ? 0 : 2}>
            <Link
              to="/team"
              style={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <Typography
                variant="proxima600"
                width="100vw"
                textAlign="center"
                color="white"
                fontSize={isMobile ? "24px" : "16px"}
              >
                Team
              </Typography>
            </Link>
            <a href="https://portal.ecliptic.capital/" width="100%">
              <Stack
                direction="row"
                spacing={1}
                width="100%"
                display={isMobile ? "flex" : undefined}
                justifyContent={isMobile ? "center" : undefined}
              >
                <Typography
                  variant="proxima600"
                  color="white"
                  fontSize={isMobile ? "24px" : "16px"}
                >
                  Portal Login
                </Typography>
                <img style={{ width: "10px" }} src={UpRightArrow}></img>
              </Stack>
            </a>
            <Stack
              pt={isMobile ? "60px" : undefined}
              display="flex"
              direction="row"
              spacing={2}
              sx={{
                display: isMobile ? "flex" : undefined,
                justifyContent: isMobile ? "center" : undefined,
              }}
            >
              <a target="_blank" href="https://twitter.com/EclipticCapital">
                <img
                  style={{ width: isMobile ? "32px" : "22px" }}
                  src={Twitter}
                ></img>
              </a>

              <a
                target="_blank"
                href="https://www.linkedin.com/company/eclipticcapital/"
              >
                <img
                  style={{ width: isMobile ? "32px" : "22px" }}
                  src={LinkedIn}
                ></img>
              </a>
            </Stack>
          </Stack>
        </Grid>

        {isMobile && (
          <Box py="30px" width="100%" display="flex" justifyContent="center">
            <NewsLetter mobile={isMobile} />
          </Box>
        )}
      </Grid>
      <Box
        width="100%"
        height="1px"
        sx={{ opacity: "25%", borderBottom: 1, color: "white" }}
      ></Box>
      <Grid container py="42px" spacing={isMobile ? 4 : 0}>
        <Grid
          item
          xs={12}
          md={8}
          display={isMobile ? "flex" : undefined}
          justifyContent={isMobile ? "center" : undefined}
        >
          <Typography
            variant="proxima600"
            textAlign="center"
            color="white"
            fontSize="16px"
          >
            © 2023 Ecliptic Capital LLC
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          justifyContent={isMobile ? "center" : "right"}
        >
          <Stack spacing={5} direction="row">
            <Link to="/privacy-policy">
              <Typography variant="proxima600" color="white" fontSize="16px">
                Privacy
              </Typography>
            </Link>
            <Link to="/terms-of-service">
              <Typography variant="proxima600" color="white" fontSize="16px">
                Terms of Service
              </Typography>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
